import { ticketReader } from "../actions";

import { Box, Divider, Typography } from "@mui/material";
import useRequest from "api/useRequest";
import { IEventTickets } from "app/Events/interfaces";
import { AxiosResponse } from "axios";
import getSubtranslation from "helpers/getSubtranslation";
import trans from "helpers/trans";
import { isEmpty } from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import {
    Button,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
} from "ui";

interface IBarcodeReaderModal {
    isGroup: boolean;
    onClose: Function;
    uuid: string;
    data: any;
}
const BarcodeReaderModal: FC<IBarcodeReaderModal> = ({
    data,
    isGroup,
    onClose,
    uuid,
}) => {
    const lang = document.documentElement.lang;
    const [ticketToUse, setTicketToUse] = useState<string>("");
    const { request, isLoading, message, status, isError } = useRequest();
    const [bookingData, setData] = useState<any>(data);
    const [isUsed, setIsUsed] = useState<boolean>(
        data.tickets.every((item: IEventTickets) => item.status === "USED")
    );

    useEffect(() => {
        if (!isEmpty(bookingData)) {
            setIsUsed(
                bookingData.tickets.every(
                    (item: IEventTickets) => item.status === "USED"
                )
            );
        }
    }, [bookingData]);

    const handleUseOneTicket = (id: string) => {
        setTicketToUse(id);
        request(
            ticketReader(
                id,
                { use_tickets: true },
                (response: AxiosResponse) => {
                    if (response.status === 200) {
                        const updatedTicket = response.data.data.tickets[0];

                        setData((prevData: any) => ({
                            ...prevData,
                            tickets: prevData.tickets.map(
                                (ticket: IEventTickets) =>
                                    ticket.id === updatedTicket.id
                                        ? updatedTicket
                                        : ticket
                            ),
                        }));

                        setTicketToUse("");
                    }
                }
            )
        );
    };

    const handleUse = () => {
        request(
            ticketReader(
                uuid,
                { use_tickets: true },
                (response: AxiosResponse) => {
                    if (response.status === 200) onClose();
                }
            )
        );
    };

    const renderInfo = () => {
        if (!bookingData) return null;
        const format = "YYYY-MM-DD HH:mm:ss";
        const utcTime = moment.utc(bookingData.event.start_datetime, format);
        const localDateTime = moment(utcTime)
            .local()
            .locale(lang === "en" ? "en-gb" : lang)
            .format("dddd, DD.MM.YYYY, HH:mm");
        const eventLocation = getSubtranslation(
            bookingData.event.subtranslations.location
        );
        const usedTickets = bookingData.tickets.filter(
            (item: IEventTickets) => item.status === "USED"
        );
        return (
            <>
                <Box>
                    <Typography>{localDateTime}</Typography>
                    <Typography>{eventLocation}</Typography>
                    {bookingData.tickets.length > 1 && (
                        <Typography>
                            {trans(`event.barcodeReader.used`)}:{" "}
                            {usedTickets.length}/{bookingData.tickets.length}
                        </Typography>
                    )}
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box display="flex" flexDirection="column" gap={1}>
                    {bookingData.tickets.map(
                        (item: IEventTickets, idx: number) => {
                            const ticket = item.event_ticket;
                            const ticketName = getSubtranslation(
                                ticket.subtranslations.name
                            );
                            return (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    key={`ticket-${idx}`}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        flexDirection="row"
                                        width={"100%"}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                gap={0.5}
                                            >
                                                {" "}
                                                <Typography>
                                                    {trans(
                                                        "event.barcodeReader.modal.ticket.name"
                                                    )}
                                                    :
                                                </Typography>
                                                <Typography
                                                    sx={{ fontWeight: 500 }}
                                                >
                                                    {ticketName}
                                                </Typography>
                                            </Box>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                gap={0.5}
                                            >
                                                {" "}
                                                <Typography>
                                                    {trans(
                                                        "event.barcodeReader.modal.ticket.uuid"
                                                    )}
                                                    :
                                                </Typography>
                                                <Typography
                                                    sx={{ fontWeight: 500 }}
                                                >
                                                    {item.uuid}
                                                </Typography>
                                            </Box>
                                            {item.status === "USED" && (
                                                <Typography
                                                    sx={{
                                                        fontSize: 16,
                                                        fontWeight: 500,
                                                    }}
                                                    color="error"
                                                >
                                                    {trans(
                                                        "event.barcodeReader.ticketInfo"
                                                    )}
                                                </Typography>
                                            )}
                                        </Box>
                                        {bookingData.tickets.length > 1 &&
                                            item.status !== "USED" && (
                                                <LoadingButton
                                                    loading={
                                                        isLoading &&
                                                        ticketToUse ===
                                                            item.uuid
                                                    }
                                                    sx={{
                                                        height: "fit-content",
                                                    }}
                                                    onClick={() =>
                                                        handleUseOneTicket(
                                                            item.uuid
                                                        )
                                                    }
                                                >
                                                    Use
                                                </LoadingButton>
                                            )}
                                    </Box>

                                    {idx ===
                                    bookingData.tickets.length - 1 ? null : (
                                        <Divider sx={{ my: 1 }} />
                                    )}
                                </Box>
                            );
                        }
                    )}
                </Box>
            </>
        );
    };

    return (
        <Modal maxWidth="xs" fullWidth onClose={() => onClose()} open>
            <ModalTitle onClose={() => onClose()}>
                {getSubtranslation(bookingData.event.subtranslations.title)}
            </ModalTitle>
            <ModalContent>
                {isError && (
                    <RequestMessage
                        message={message}
                        status={status}
                        sx={{ mb: 2 }}
                    />
                )}
                {renderInfo()}
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    {!isUsed && (
                        <Button
                            onClick={() => onClose()}
                            color="inherit"
                            variant="text"
                            sx={{ mr: 1 }}
                        >
                            {trans("global.button.cancel")}
                        </Button>
                    )}
                    {isUsed ? (
                        <Button sx={{ flex: 1 }} onClick={() => onClose()}>
                            {trans("global.button.ok")}
                        </Button>
                    ) : (
                        <LoadingButton
                            loading={isLoading && !ticketToUse}
                            sx={{ flex: 1 }}
                            onClick={handleUse}
                        >
                            {isGroup
                                ? trans(
                                      "event.barcodeReader.button.markAllAsUsed"
                                  )
                                : trans(
                                      "event.barcodeReader.button.markAsUsed"
                                  )}
                        </LoadingButton>
                    )}
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default BarcodeReaderModal;
