import { Box } from "@mui/material";
import trans from "helpers/trans";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Modal, ModalActions, ModalContent, ModalTitle } from "ui";
import { modalHide } from "../actions";

const ReleaseModal: FC = () => {
    const dispatch = useDispatch();
    const releaseKey = `release-${process.env.REACT_APP_VERSION}`;
    const [value, setValue] = useState(true);

    //remove previous keys
    useEffect(() => {
        Object.keys(localStorage).forEach((key) => {
            if (key.indexOf("release") === 0 && key !== releaseKey) {
                localStorage.removeItem(key);
            }
        });
    }, [releaseKey]);

    const handleChange = () => {
        setValue((prevValue) => !prevValue);
    };

    const handleClose = () => {
        if (value) {
            localStorage.setItem(releaseKey, "true");
        } else {
            localStorage.removeItem(releaseKey);
        }

        dispatch(modalHide("ReleaseModal"));
    };

    return (
        <Modal maxWidth="sm" fullWidth onClose={handleClose} open>
            <ModalTitle onClose={handleClose}>
                {trans("event.app.modal.releaseModal.title", {
                    appVersion: process.env.REACT_APP_VERSION,
                })}
            </ModalTitle>

            <ModalContent>
                <Box
                    dangerouslySetInnerHTML={{
                        __html: trans("event.app.modal.releaseModal.list"),
                    }}
                />
            </ModalContent>
            <ModalActions sx={{ justifyContent: "flex-start" }}>
                <Checkbox
                    checked={value}
                    label={trans("event.app.modal.releaseModal.label")}
                    id="release"
                    onChange={handleChange}
                />
            </ModalActions>
        </Modal>
    );
};

export default ReleaseModal;
