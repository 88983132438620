export function tableUpdate(state, action) {
    const extraProps = {};

    switch (action.action) {
        case "columns":
            return {
                ...state,
                columns: action.data,
            };

        case "clearFilters":
            if (!!state.elasticSearch && Object.keys(state.sort).length === 0) {
                extraProps.sort = { ...state.prevSort };
            }

            return {
                ...state,
                filterSettings: {
                    ...state.filterSettings,
                    total: 0,
                },
                filters: getDefaultFilters(state.filterData),
                search: "",
                selection: {
                    ...state.selection,
                    selected: [],
                },
                ...extraProps,
            };

        case "filter": {
            if (!!state.pagination) {
                extraProps.pagination = {
                    ...state.pagination,
                    page: 1,
                };
            }

            if (!!state.filterSettings) {
                extraProps.filterSettings = {
                    ...state.filterSettings,
                    total:
                        countFilters(state.filterData, action.data, state.id) +
                        (state.search.length > 0 ? 1 : 0),
                };
            }

            return {
                ...state,
                filters: action.data,
                selection: {
                    ...state.selection,
                    selected: [],
                },
                ...extraProps,
            };
        }
        case "filterSettingsOpen":
            return {
                ...state,
                filterSettings: {
                    ...state.filterSettings,
                    open: action.data,
                },
            };

        case "page":
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.data,
                },
                selection: {
                    ...state.selection,
                    selected: [],
                },
            };

        case "per_page":
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: 1,
                    per_page: action.data,
                },
            };

        case "reload":
            return {
                ...state,
                reload: Date.now(),
            };

        case "selection":
            return {
                ...state,
                selection: {
                    ...state.selection,
                    selected: action.data,
                },
            };

        case "search": {
            if (state.search === action.data) {
                return state;
            }

            if (!!state.pagination) {
                extraProps.pagination = {
                    ...state.pagination,
                    page: 1,
                };
            }

            if (!!state.filterSettings) {
                extraProps.filterSettings = {
                    ...state.filterSettings,
                    total:
                        countFilters(
                            state.filterData,
                            state.filters,
                            state.id
                        ) + (action.data.length > 0 ? 1 : 0),
                };
            }

            if (!!state.elasticSearch) {
                if (action.data) {
                    if (Object.keys(state.sort).length > 0) {
                        extraProps.prevSort = { ...state.sort };
                    }
                    extraProps.sort = {};
                } else if (Object.keys(state.sort).length === 0) {
                    extraProps.sort = { ...state.prevSort };
                }
            }

            return {
                ...state,
                search: action.data,
                ...extraProps,
                selection: {
                    ...state.selection,
                    selected: [],
                },
            };
        }
        case "sort":
            return {
                ...state,
                pagination: !!state.pagination
                    ? {
                          ...state.pagination,
                          page: 1,
                      }
                    : undefined,
                sort: action.data,
                prevSort: { ...state.sort },
                selection: {
                    ...state.selection,
                    selected: [],
                },
            };

        default:
            return state;
    }
}

export function countFilters(availableFilters, filters, id) {
    return availableFilters.reduce((counter, el) => {
        if (id === "WebEvent" && el.id in filters) {
            return counter + 1;
        } else if (el.id in filters && el.show) {
            return counter + 1;
        }
        return counter;
    }, 0);
}

export function getDefaultFilters(availableFilters) {
    let defaultFilters = {};

    availableFilters.forEach((filter) => {
        if (filter.required && filter.default_value !== null) {
            defaultFilters = {
                ...defaultFilters,
                [filter.id]: filter.default_value,
            };
        }
    });

    return defaultFilters;
}

export function tableResponse(state, action) {
    if (!!state.pagination) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                isLastPage:
                    action.response.data.meta.page ===
                    action.response.data.meta.last_page,
                page: action.response.data.meta.page,
                total: action.response.data.meta.total,
            },
        };
    }

    return state;
}

export class rs {
    static init(state) {
        let requests = {};
        for (let i in state.request) requests[i] = f.initState;

        return {
            ...state,
            request: requests,
        };
    }

    // REQUESTS
    static request(state, action, place) {
        const ret = {
            ...state,
            data: {
                ...state.data,
                [place]: undefined,
            },
            request: {
                ...state.request,
                [place]: f.loading(),
            },
        };

        if (action.filters !== undefined) {
            ret.filters = {
                ...state.filters,
                ...action.filters,
            };
        }

        return ret;
    }

    // SUCCESS
    static success(state, action, place) {
        return {
            ...state,
            request: {
                ...state.request,
                [place]: f.clean(action),
            },
        };
    }

    static requestSuccess(state, action, place, dataPlace) {
        if (dataPlace === undefined) dataPlace = place;

        const ret = {
            ...state,
            data: {
                ...state.data,
                [dataPlace]: action.response.data,
            },
            request: {
                ...state.request,
                [place]: f.clean(action),
            },
        };

        return ret;
    }

    static tableSuccess(state, action) {
        let pagination = undefined;
        if (!!state.pagination) {
            pagination = {
                ...state.pagination,
                isLastPage:
                    action.response.meta.page ===
                    action.response.meta.last_page,
                page: action.response.meta.page,
                total: action.response.meta.total,
            };
        }
        return {
            ...state,
            loading: false,
            pagination,
            rows: action.response.data,
        };
    }

    // FAILED
    static failed(state, action, place) {
        return {
            ...state,
            request: {
                ...state.request,
                [place]: f.failed(action),
            },
        };
    }
}

export class f {
    static initState = {
        loading: false,
        message: "",
        errors: {},
        status: 0,
    };

    static update(state, action, type) {
        if (action.form === undefined) return state.forms;

        return {
            ...state.forms,
            [action.form]: type(this, action),
        };
    }

    //---------------------

    static clean(action) {
        const ret = { ...this.initState };

        if (action !== undefined) {
            if (action.response !== undefined) {
                if (action.response.message !== undefined) {
                    ret.message = action.response.message;
                }
            }

            if (action.status !== undefined) {
                ret.status = action.status;
            }
        }

        return ret;
    }

    static failed(action) {
        return {
            ...this.initState,
            message: action.message,
            errors: action.errors !== undefined ? action.errors : {},
            status: action.status,
        };
    }

    static loading() {
        return { ...this.initState, loading: true };
    }
}
