import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    ticketReader: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/tickets/${url.id}`, data, config),
};

export default requests;
