import React from "react";
import { Box, Drawer } from "@mui/material";
import { DrawerProps } from "@mui/material";

interface ISideNav extends DrawerProps {
    drawerOpen?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    size?: string;
}

const SideNav = ({
    children,
    drawerOpen = true,
    color = "primary",
    disabled = false,
    size = "medium",
    variant = "permanent",
    ...restProps
}: ISideNav) => {
    return (
        <Drawer
            {...restProps}
            variant={variant}
            color={color}
            sx={[
                {
                    width: (theme) => theme.sideNav.width,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        background: (theme) => theme.sideNav.background,
                        color: (theme) => theme.sideNav.color,
                        width: (theme) =>
                            drawerOpen ? theme.sideNav.width : 20,

                        "&>#side-nav-content": {
                            display: drawerOpen ? "block" : "none",
                        },

                        "&:hover": {
                            width: (theme) => theme.sideNav.width,

                            "&>#side-nav-content": {
                                display: "block",
                            },
                        },
                    },
                    zIndex: (theme) => theme.zIndex.drawer,
                },
                !drawerOpen && { width: 20 },
            ]}
        >
            <Box id="side-nav-content" sx={{ overflow: "auto" }}>
                {children}
            </Box>
        </Drawer>
    );
};

export default SideNav;
