import BarcodeReaderModal from "./modals/BarcodeReaderModal"
import BarcodeReader from "./reducers/BarcodeReaderReducer";
import BarcodeReaderSaga from "./saga";

import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import { IConfig } from "./interfaces";
import { registerModals } from "modals";

export const permissionKey = "ticket";
export const url = `/ticket-reader`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            use: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".use") !== -1) {
        config.permissions.use = true;

        store.reducerManager?.add("BarcodeReader", BarcodeReader);
        registerModals({ BarcodeReaderModal });
        store.injectSaga?.("BarcodeReaderSaga", BarcodeReaderSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="BarcodeReader/pages/BarcodeReaderIndexPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
