import { removePrefixFiltersArray } from "helpers/urlParams";
import {
    FETCH_SUCCESS,
    FILTERS_SUCCESS,
    SCROLL,
    SETTINGS_FAILED,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    TABLE_UPDATE,
} from "./types";

import { tableResponse, tableUpdate } from "helpers/reducers";
import { AnyAction } from "redux";
import { APP_LOADED } from "types";
import { IGPTable } from "ui/Table/Table";
interface IWebEvent
    extends Omit<
        IGPTable,
        "available_columns" | "filterSettings" | "pagination"
    > {
    uuid: string;
    filterSettings: {
        open: boolean;
        total: number;
    };
    expand: "";
    mobileFilters: boolean;
    name: string;
    settingsLoader: boolean;
    scroll: boolean;
    filtersLoader: boolean;
    events: any;
    status: string;
    settings: any;
}

function WebEvent(
    state: IWebEvent = {
        id: "WebEvent",
        filters: {},
        filterData: [],
        filterSettings: {
            open: false,
            total: 0,
        },
        columns: [],
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        with: [],
        uuid: "",
        expand: "",
        mobileFilters: false,
        name: "",
        settingsLoader: true,
        scroll: false,
        events: null,
        elasticSearch: true,
        settings: {},
        status: "",
        filtersLoader: true,
        sort: {},
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            return {
                ...tableResponse(state, action),
                events: action.response.data.data || {},
            };

        case TABLE_UPDATE:
            return {
                ...tableUpdate(state, action),
                events: null,
            };

        case SETTINGS_REQUEST:
            return { ...state, settingsLoader: true };
        case SETTINGS_FAILED:
            return { ...state, settingsLoader: false };
        case SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.response.data.data,
                settingsLoader: false,
            };

        case FILTERS_SUCCESS:
            return {
                ...state,
                filtersLoader: false,
                filterData: action.response.data.filter(
                    (item: any) => item.values?.length > 0 || !item.values
                ),
            };

        case APP_LOADED:
            return {
                ...state,
                uuid: action.uuid,
                status: action.status || "",
                filtersList: removePrefixFiltersArray(action.filtersList, true),
            };
        case SCROLL:
            return {
                ...state,
                scroll: true,
            };
        default:
            return state;
    }
}

export default WebEvent;
