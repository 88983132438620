import { api, web } from "apiConnections";

const setLocationHeader = (
    location: number | null = null,
    isWeb: boolean = false
) => {
    if (location) {
        if (isWeb) {
            web.defaults.headers.common["X-GP-LOCATION-ID"] = location;
        } else api.defaults.headers.common["X-GP-LOCATION-ID"] = location;
    } else {
        if (isWeb) {
            delete web.defaults.headers.common["X-GP-LOCATION-ID"];
        } else delete api.defaults.headers.common["X-GP-LOCATION-ID"];
    }
};

export default setLocationHeader;
