import { AnyAction } from "redux";
import { MODAL_HIDE, MODAL_SHOW } from "types";

function BarcodeReader(
    state: any = {
        isScanActive: true,
    },
    action: AnyAction
) {
    switch (action.type) {
        case MODAL_HIDE:
            return {
                ...state,
                isScanActive: true,
            };

        case MODAL_SHOW:
            return {
                ...state,
                isScanActive: false,
            };

        default:
            return state;
    }
}

export default BarcodeReader;
