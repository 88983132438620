import { Box, Stack, useMediaQuery } from "@mui/material";
import trans from "helpers/trans";
// import trans from "helpers/trans";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HIDE_COOKIE_CONSENT } from "types";
import { Button, Modal, ModalActions, ModalContent, ModalTitle } from "ui";
interface ICookiesModal {}

const CookieModal: FC<ICookiesModal> = () => {
    const dispatch = useDispatch();
    const smallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("tablet")
    );
    const handleRequired = () => {
        localStorage.setItem("consent", "REQUIRED");
        dispatch({ type: HIDE_COOKIE_CONSENT, showCookie: false });
    };
    const handleAccept = () => {
        localStorage.setItem("consent", "ALL");
        dispatch({ type: HIDE_COOKIE_CONSENT, showCookie: false });
    };

    return (
        <Modal
            maxWidth="sm"
            fullWidth
            open
            fullScreen={smallScreen}
            scroll="paper"
        >
            <ModalTitle sx={{ px: 4, py: 2 }}>
                {trans("event.webEvent.modal.cookieModal.title")}
            </ModalTitle>

            <ModalContent
                sx={{
                    pt: 0,
                    px: 4,
                    display: "flex",
                    flexDirection: "column",
                    pb: 0,
                }}
            >
                <Box
                    sx={{
                        fontSize: { xs: 14, laptop: 16 },
                        fontWeight: 400,
                        fontFamily: "'Noto Sans',sans-serif",
                    }}
                >
                    {trans("event.webEvent.modal.cookieModal.text")}
                </Box>
            </ModalContent>
            <ModalActions sx={{ pt: 3, px: 4, pb: 3.75 }}>
                <Box
                    display="flex"
                    width="100%"
                    textAlign="right"
                    flexDirection="column"
                    justifyContent="center"
                    gap={3}
                >
                    <Stack
                        direction="row"
                        sx={{
                            marginLeft: "0!important",
                            width: "100%",
                            gap: { xs: 1, tablet: 2 },
                            p: 0,
                        }}
                    >
                        <Button
                            component={Link}
                            variant="text"
                            to="https://gastroplanner.eu/"
                            sx={{
                                display: "flex",
                                flex: 1,
                                m: "auto",
                            }}
                        >
                            {trans("event.webEvent.modal.cookieModal.reject")}
                        </Button>
                        <Button
                            onClick={handleRequired}
                            variant="outlined"
                            sx={{
                                display: "flex",
                                flex: 1,
                                m: "auto",
                            }}
                        >
                            {trans("event.webEvent.modal.cookieModal.required")}
                        </Button>
                        <Button
                            onClick={handleAccept}
                            sx={{
                                display: "flex",
                                flex: 1,
                                m: "auto",
                            }}
                        >
                            {trans("event.webEvent.modal.cookieModal.accept")}
                        </Button>
                    </Stack>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default CookieModal;
