import { Box, Stack, useMediaQuery } from "@mui/material";
import trans from "helpers/trans";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalActions, ModalContent, ModalTitle } from "ui";
interface ITermssModal {
    onCloseModal: Function;
    text: string;
}

const TermsModal: FC<ITermssModal> = ({
    onCloseModal,
    text,
}) => {
    const smallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("tablet")
    );
    const primaryColor = useSelector(
        (state: any) =>
            state.web.settings.location_settings["general-primary_color"]
    );

    return (
        <Modal
            maxWidth="sm"
            fullWidth
            open
            fullScreen={smallScreen}
            scroll="paper"
        >
            <ModalTitle onClose={() => onCloseModal()}></ModalTitle>

            <ModalContent
                sx={{
                    pt: 0,
                    px: 4,
                    display: "flex",
                    flexDirection: "column",
                    pb: 0,
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        fontSize: { xs: 14, laptop: 16 },
                        fontWeight: 400,
                        fontFamily: "'Noto Sans',sans-serif",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            </ModalContent>
            <ModalActions sx={{ pt: 3, px: 4, pb: 3.75 }}>
                <Box
                    display="flex"
                    width="100%"
                    textAlign="right"
                    flexDirection="column"
                    justifyContent="center"
                    gap={3}
                >
                    <Stack
                        direction="row"
                        sx={{
                            marginLeft: "0!important",
                            width: "100%",
                            gap: 1,
                            p: 0,
                        }}
                    >
                        <Button
                            onClick={() => onCloseModal()}
                            sx={{
                                ":hover": {
                                    background: primaryColor,
                                    boxShadow: "none",
                                },
                                background: primaryColor,
                                fontFamily: {
                                    xs: "'Source Sans 3', sans-serif",
                                    laptop: "'Lato', sans-serif",
                                },
                                fontWeight: { xs: 600, laptop: 500 },
                                fontSize: { xs: 18, laptop: 24 },
                                borderRadius: 1,
                                boxShadow: "none",
                                padding: "0 16px",
                                height: 48,
                                display: "flex",
                                flex: 1,
                                m: "auto",
                                color: (theme) =>
                                    theme.palette.getContrastText(primaryColor),
                            }}
                        >
                            {trans("global.button.close")}
                        </Button>
                    </Stack>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default TermsModal;
