import useRequest from "api/useRequest";
import { bookingsExport } from "../actions";
import { Box, MenuItem } from "@mui/material";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    DateTimePickerInput,
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Select,
    Settings,
    SettingsItem,
} from "ui";
import { statuses } from "../interfaces";
import moment from "moment";
import { AxiosResponse } from "axios";

interface IBookingsExportModal {}

const BookingsExportModal: FC<IBookingsExportModal> = () => {
    const dateTimeFormat = "DD/MM/YYYY HH:mm";
    const dataFormat = "YYYY-MM-DD HH:mm:ss";

    const dispatch = useDispatch();
    const formRef = useRef<any>(null);
    const [message, setMessage] = useState<string>("");
    const [errors, setErrors] = useState<any>({});

    const { request, isLoading, status } = useRequest();

    const handleCloseModal = () => {
        dispatch(modalHide("BookingsExportModal"));
    };

    const handleSave = () => {
        if (formRef.current) {
            setErrors({});
            setMessage("");
            const data = formRef.current.getData();
            const from = moment(data.from, dateTimeFormat)
                .utc()
                .format(dataFormat);
            const to = moment(data.to, dateTimeFormat).utc().format(dataFormat);
            request(
                bookingsExport(
                    { params: { ...data, from, to } },
                    async (response: AxiosResponse) => {
                        if (response.status === 200) {
                            dispatch(modalHide("BookingsExportModal"));
                        } else {
                            if (response.data instanceof Blob) {
                                const errorText = await response.data.text();
                                try {
                                    const errorJSON = JSON.parse(errorText);
                                    setErrors(errorJSON.errors);
                                    setMessage(errorJSON.message);
                                } catch (err) {
                                    setErrors({});
                                    setMessage("");
                                }
                            }
                        }
                    }
                )
            );
        }
    };

    return (
        <Modal open fullWidth maxWidth="xs">
            <ModalTitle onClose={handleCloseModal}>
                {trans("event.bookings.modal.bookingsExportModal.title")}
            </ModalTitle>
            <ModalContent>
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{
                        mb: 1,
                    }}
                />
                <Form
                    ref={formRef}
                    data={{}}
                    fields={{
                        statuses: {
                            default: statuses,
                        },
                        from: {
                            default: moment()
                                .subtract(1, "hours")
                                .format(dateTimeFormat),
                        },
                        to: {
                            default: moment().format(dateTimeFormat),
                        },
                    }}
                    errors={errors}
                    onSubmit={() => {}}
                    unsaved={false}
                >
                    <Settings>
                        <SettingsItem
                            label={trans(
                                "event.bookings.modal.bookingsExportModal.label.statuses"
                            )}
                        >
                            <Select id="statuses" fullWidth multiple>
                                {statuses.map((status: string) => {
                                    return (
                                        <MenuItem
                                            value={status}
                                            key={`status-${status}`}
                                        >
                                            {status}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </SettingsItem>
                        <SettingsItem
                            label={trans(
                                "event.bookings.modal.bookingsExportModal.label.from"
                            )}
                        >
                            <DateTimePickerInput id="from" fullWidth />
                        </SettingsItem>
                        <SettingsItem
                            label={trans(
                                "event.bookings.modal.bookingsExportModal.label.to"
                            )}
                        >
                            <DateTimePickerInput id="to" fullWidth />
                        </SettingsItem>
                    </Settings>
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <LoadingButton
                        sx={{ flex: 1 }}
                        onClick={handleSave}
                        loading={isLoading}
                    >
                        {trans("global.button.export")}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};
export default BookingsExportModal;
