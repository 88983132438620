import store from "rootStore";
import PermissionsTable from "./reducers/PermissionsTableReducer";
import PermissionsSaga from "./saga";

import { registerRoutings } from "rootRouting";
import { permissionsType, configType, routingType } from "types/configTypes";

export const permissionKey = "permission";
export const url = "/permission";

const getConfig = (permissions: permissionsType) => {
    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    config.permissions.read = true;
    store.injectSaga?.("PermissionsSaga", PermissionsSaga);
    store.reducerManager?.add("PermissionsTable", PermissionsTable);

    // if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
    //     config.permissions.read = true;

    //     routing.push({
    //         path: url,
    //         element: <AsyncComponent path="Permissions/pages/PermissionsIndexPage" />,
    //     });
    //     routing.push({
    //         path: `${url}/:id`,
    //         element: <AsyncComponent path="Permissions/pages/PermissionsReadPage" />,
    //     });
    // }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
