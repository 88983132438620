import { registerModals } from "modals";
import LanguagesCreateModal from "./modals/LanguagesCreateModal";
import LanguagesTable from "./reducers/LanguagesTableReducer";
import LanguagesSaga from "./saga";

import { url as settingsUrl } from "app/Settings/config";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, configType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "language";
export const url = `${settingsUrl}/languages`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("LanguagesTable", LanguagesTable);
        store.injectSaga?.("LanguagesSaga", LanguagesSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Languages/pages/LanguagesIndexPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        registerModals({
            LanguagesCreateModal,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
