import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";

function* ticketReader(action: AnyAction): any {
    const response = yield call(request, api.ticketReader, action, "put");
    const useTickets = action.axiosConfig.data.use_tickets
    if (response.status === 200 && useTickets) {
        yield put(
            snackbarShow({
                content: trans("event.barcodeReader.saga.content.use"),
            })
        );  
    }
}

export default function* saga() {
    yield takeLatest(types.TICKET_READER_REQUEST, ticketReader);
}
