import {
    FETCH_SUCCESS,
    TABLE_UPDATE,
} from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { IGPTable } from "ui/Table/Table";
import { AnyAction } from "redux";
import trans from "helpers/trans";

function PaymentsTableReducer(
    state: IGPTable = {
        id: "PaymentsTable",
        columns: [
            {
                field: "name",
                headName: trans("tableManagement.PaymentsTable.name"),
                sortable: true,
            },
            {
                field: "actions",
            },
        ],
        pagination: {
            isLastPage: true,
            page: 1,
            per_page: 20,
            total: 0,
        },
        search: "",
        sort: { provider: "asc" },
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

        case TABLE_UPDATE:
            return tableUpdate(state, action);

        default:
            return state;
    }
}

export default PaymentsTableReducer;
