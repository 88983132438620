import TicketModal from "./modals/TicketModal";
import TermsModal from "./modals/TermsModal";
import UpsaleImageModal from "./modals/UpsaleImageModal";

import { registerModals } from "modals";
import { registerPublicRoutings } from "rootRouting";
import { IConfig, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const url = "/tickets";
export const uuid = window.location.pathname.match(
    /\/tickets\/([A-Za-z0-9-]+)/
)?.[1];

const getConfig = () => {
    const config: IConfig = {
        permissions: {
            create: true,
            delete: true,
            read: true,
            update: true,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    registerModals({ TicketModal, TermsModal, UpsaleImageModal });
    routing.push(
        {
            path: `${url}/${uuid}`,
            element: <AsyncComponent path="WebEvent/pages/WebEventIndexPage" />,
        },
        {
            path: `${url}/${uuid}/privacy-policy`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventPrivacyPolicyPage" />
            ),
        },
        {
            path: `${url}/${uuid}/terms-and-conditions`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventTermsAndConditionsPage" />
            ),
        },
        {
            path: `${url}/${uuid}/event/:id`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventDetailsPage" />
            ),
        },
        // {
        //     path: `${url}/${uuid}/event/:id/arrangement`,
        //     element: (
        //         <AsyncComponent path="WebEvent/pages/WebEventArrangementPage" />
        //     ),
        // },
        // {
        //     path: `${url}/${uuid}/event/:id/group-tickets`,
        //     element: (
        //         <AsyncComponent path="WebEvent/pages/WebEventGroupTicketsPage" />
        //     ),
        // },
        {
            path: `${url}/${uuid}/basket`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventBasketPage" />
            ),
        },
        {
            path: `${url}/${uuid}/checkout`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventCheckoutPage" />
            ),
        },
        {
            path: `${url}/${uuid}/thank-you`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventThankYouPage" />
            ),
        },
        {
            path: `${url}/${uuid}/checkout-failed`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventFailedPage" />
            ),
        }
    );
    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerPublicRoutings(routing);
    }

    return config;
};

export default getConfig;
