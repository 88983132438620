export const FETCH_SUCCESS = "PAYMENTS/FETCH_SUCCESS";
export const FETCH_ALL_SUCCESS = "PAYMENTS/FETCH_ALL_SUCCESS";
export const TABLE_UPDATE = "PAYMENTS/TABLE_UPDATE";

const types = {
    DESTROY_FAILED: "PAYMENTS/DESTROY_FAILED",
    DESTROY_REQUEST: "PAYMENTS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "PAYMENTS/DESTROY_SUCCESS",

    FETCH_FAILED: "PAYMENTS/FETCH_FAILED",
    FETCH_REQUEST: "PAYMENTS/FETCH_REQUEST",
    FETCH_SUCCESS,

    FETCH_ALL_FAILED: "PAYMENTS/FETCH_ALL_FAILED",
    FETCH_ALL_REQUEST: "PAYMENTS/FETCH_ALL_REQUEST",
    FETCH_ALL_SUCCESS,

    FETCH_DEFAULT_FAILED: "PAYMENTS/FETCH_DEFAULT_FAILED",
    FETCH_DEFAULT_REQUEST: "PAYMENTS/FETCH_DEFAULT_REQUEST",
    FETCH_DEFAULT_SUCCESS: "PAYMENTS/FETCH_DEFAULT_SUCCESS",

    GET_FAILED: "PAYMENTS/GET_FAILED",
    GET_REQUEST: "PAYMENTS/GET_REQUEST",
    GET_SUCCESS: "PAYMENTS/GET_SUCCESS",

    STORE_FAILED: "PAYMENTS/STORE_FAILED",
    STORE_REQUEST: "PAYMENTS/STORE_REQUEST",
    STORE_SUCCESS: "PAYMENTS/STORE_SUCCESS",

    STORE_GENERATE_KEY_FAILED: "PAYMENTS/STORE_GENERATE_KEY_FAILED",
    STORE_GENERATE_KEY_REQUEST: "PAYMENTS/STORE_GENERATE_KEY_REQUEST",
    STORE_GENERATE_KEY_SUCCESS: "PAYMENTS/STORE_GENERATE_KEY_SUCCESS",

    UPDATE_FAILED: "PAYMENTS/UPDATE_FAILED",
    UPDATE_REQUEST: "PAYMENTS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "PAYMENTS/UPDATE_SUCCESS",
    
    UPDATE_GENERATE_KEY_FAILED: "PAYMENTS/UPDATE_GENERATE_KEY_FAILED",
    UPDATE_GENERATE_KEY_REQUEST: "PAYMENTS/UPDATE_GENERATE_KEY_REQUEST",
    UPDATE_GENERATE_KEY_SUCCESS: "PAYMENTS/UPDATE_GENERATE_KEY_SUCCESS",

    TABLE_UPDATE,
};

export default types;
