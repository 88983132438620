import TextField from "@mui/material/TextField";
import React, { forwardRef, useContext } from "react";
import { TextFieldProps } from "@mui/material/TextField";

import FormContext from "./FormContext";
import { getHelperText, getValue, regexValue } from "./helpers";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { countriesList } from "CountriesISO";
import { LanguageModel } from "app/Languages/interfaces";

export interface IGPTranslationsTextField
    extends Omit<TextFieldProps, "onChange"> {
    errorText?: string;
    id?: string;
    onChange?: Function;
    regex?: string;
    prefixId: string;
}

const GPTranslationsTextField = forwardRef<any, IGPTranslationsTextField>(
    (props, ref) => {
        const {
            errorText = "",
            helperText = "",
            onChange,
            regex,
            value,
            prefixId,
            size = "small",
            ...restProps
        } = props;
        const currentLang = useSelector((state: any) => state.app.lang);
        const languages = useSelector(
            (state: any) => state.app.settings.languages
        );
        const currentLangObj = languages?.find(
            (lang: LanguageModel) => lang.key === currentLang
        );
        const currentCountry = countriesList.find(
            (country) => country.country === currentLangObj?.flag
        );
        const id = `${prefixId}_${currentLang}`;

        const context = useContext(FormContext);

        const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
            const val = regexValue(id, context, regex, event.target.value);

            if (!!onChange) {
                onChange({ id, value: val }, context);
            } else {
                context?.onChange({ id, value: val });
            }
        };

        const { inputHelperText, isError } = getHelperText(
            id,
            context,
            errorText,
            helperText
        );

        return (
            <TextField
                {...restProps}
                error={isError}
                size={size}
                helperText={inputHelperText}
                onChange={onChangeValue}
                ref={ref}
                value={getValue(id, context, value)}
                InputProps={{
                    endAdornment: currentCountry && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                className={`fflag ff-sm fflag-${currentCountry.flag.toUpperCase()}`}
                            />
                        </Box>
                    ),
                }}
            />
        );
    }
);

export default GPTranslationsTextField;
