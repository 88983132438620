import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IGPUIButton extends Omit<ButtonProps, "component"> {
    component?: any;
    to?: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
})) as typeof Button;

const GPUIButton = ({
    children,
    variant = "contained",
    ...restProps
}: IGPUIButton) => {
    return (
        <StyledButton {...restProps} variant={variant}>
            {children}
        </StyledButton>
    );
};

export default GPUIButton;
