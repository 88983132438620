import {
    FormControl,
    FormControlLabelProps,
    FormHelperText,
    FormLabel,
    RadioGroup,
    SxProps,
} from "@mui/material";
import React, { useContext } from "react";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";

interface IGPRadio  {
    checked?: boolean;
    errorText?: string;
    formControlProps?: {};
    formControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
    helperText?: string;
    label?: string;
    id: string;
    value?: boolean | null;
    onChange?: Function;
    children: React.ReactNode;
    fullWidth?: boolean;
    sx?: SxProps;
    size?: "small" | "medium" | undefined;
}

function GPRadio({
    children,
    id,
    label = "",
    onChange,
    checked,
    errorText = "",
    formControlLabelProps = {},
    formControlProps = {},
    fullWidth,
    helperText = "",
    value,
    sx,
    size,
    ...restProps
}: IGPRadio) {
    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!!onChange) {
            onChange(
                {
                    id,
                    value:  event.target.value,
                    checked: event.target.checked,
                    event: event,
                },
                context
            );
        } else {
            context?.onChange({
                id,
                checked: event.target.checked,
                value: event.target.value,
            });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <FormControl
            {...formControlProps}
            error={isError}
            fullWidth={fullWidth}
            size={size}
            sx={sx}
        >
            {!!label && <FormLabel>{label}</FormLabel>}
            <RadioGroup
                {...restProps}
                value={getValue(id, context, value)}
                onChange={onChangeValue}
            >
                {children}
            </RadioGroup>
            {inputHelperText.length > 0 && (
                <FormHelperText>{inputHelperText}</FormHelperText>
            )}
        </FormControl>
    );
}

export default GPRadio;
