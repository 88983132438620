import { FC } from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component"
import AppLoader from "app/App/components/AppLoader";

const apps = {
    App: loadable(() => import("app/App")),
    WebEvent: loadable(() => import("app/WebEvent")),
};

const AppInit: FC = () => {
    const { app, loaded } = useSelector((state: any) => state.app);

    if (!loaded) {
        return <AppLoader />;
    }

    const Component = apps[app as keyof typeof apps];
    return <Component />;
};

export default AppInit;
