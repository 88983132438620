import { nanoid } from "nanoid";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requests } from "./apiSaga";
import { unsubscribe } from "./actions";
import isEqual from "lodash/isEqual";

function useRequest(action, options = {}) {
    const [cacheKey, setCacheKey] = useState(() => {
        let ck = undefined;

        if (typeof action === "function") {
            ck = action()?.query?.cacheKey;
        } else if (!!action) {
            ck = action.query.cacheKey;
        }

        return ck;
    });

    const query = useSelector((state) => state.api.queries[cacheKey]);

    const dispatch = useDispatch();
    const axiosConfig = useRef();

    let requestUid = useRef("");
    const subscribeUid = useRef(nanoid());

    const cancel = useCallback(() => {
        if (!!requests[requestUid.current]) {
            requests[requestUid.current].abort();
        }
    }, []);

    const request = useCallback(
        (action) => {
            requestUid.current = nanoid();

            if (cacheKey !== action.query.cacheKey) {
                setCacheKey(action.query.cacheKey);
            }

            dispatch({
                ...action,
                query: {
                    ...action.query,
                    subscribeUid: subscribeUid.current,
                },
                uid: requestUid.current,
            });
        },
        [cacheKey, dispatch]
    );

    const reload = useCallback(() => {
        request(action);
    }, [action, request]);

    useEffect(() => {
        const unsub = () => {
            cancel();

            if (requestUid.current !== "") {
                dispatch(unsubscribe(subscribeUid.current));
            }
        };
        return unsub;
    }, [cancel, dispatch]);

    useEffect(() => {
        if (
            cacheKey &&
            action &&
            !isEqual(axiosConfig.current, action.axiosConfig)
        ) {
            axiosConfig.current = action.axiosConfig;
            request(action);
        }
    }, [action, cacheKey, request]);

    if (query === undefined) {
        return {
            currentData: undefined,
            data: undefined,
            errors: {},
            isError: false,
            isLoading: false,
            isPending: true,
            isSuccess: false,
            message: "",
            status: 0,
            cancel,
            reload,
            request,
        };
    }

    return {
        ...query,
        cancel,
        reload,
        request,
    };
}

useRequest.propTypes = {};

export default useRequest;
