import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    fetch: (config: AxiosRequestConfig) => api.get(`/permissions`, config),
    fetchAll: (config: AxiosRequestConfig) => api.get(`/permissions/all`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/permissions/${url.id}`, config),
};

export default requests;
