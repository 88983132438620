import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material/Box";
import React from "react";

interface IPageContent extends BoxProps {
    children: React.ReactNode;
    py?: { xs: number; sm: number };
    px?: { xs: number; sm: number };
}

function PageContent({
    children,
    px = { xs: 1.5, sm: 3 },
    py = { xs: 1, sm: 2 },
    ...restProps
}: IPageContent) {
    return (
        <Box px={px} py={py} {...restProps}>
            {children}
        </Box>
    );
}

export default PageContent;
