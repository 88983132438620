import {
    Box,
    Paper,
    Table,
    TableContainer,
    TableCellProps,
    TableRowProps,
    TableProps,
    TableContainerProps,
} from "@mui/material";
import { FC, ReactNode, useEffect, useRef, useState } from "react";

import TableBody from "./TableBody";
import TableHead from "./TableHead";
import TableNoData from "./TableNoData";
import TablePagination from "./TablePagination";
import { TableLoader } from "ui";
import { SX } from "types/SX";

export type IGPTableSortDirections = "desc" | "asc";

export interface IGPTableSort {
    [key: string]: IGPTableSortDirections;
}
export interface IGPTablePagination {
    isLastPage: boolean;
    page: number;
    per_page: number;
    total: number;
}
export interface IGPTable {
    availableColumns?: IGPTableColumn[];
    calculateHeight?: boolean | Function;
    calculateWidth?: boolean;
    columns: IGPTableColumn[];
    elasticSearch?: boolean;
    filters?: object;
    filterData?: any;
    filterSettings?: ITableFilterSettings;
    id: string;
    idKey?: string;
    loading?: boolean;
    onClickRow?: (event: React.MouseEvent, item: any) => void;
    organizeColumns?: IGPTableColumn[];
    pagination?: IGPTablePagination;
    renderFooter?: Function;
    renderHeader?: Function;
    renderRow?: (
        item: any,
        idx: number,
        content: TableRowProps,
        columns: IGPTableColumn[]
    ) => React.ReactNode;
    rows?: any;
    search?: string;
    selection?: IGPTableSelection;
    selectionActions?: ReactNode;
    sort?: IGPTableSort;
    prevSort?: IGPTableSort;
    stickyHeader?: boolean;
    reload?: number;
    rowHover?: boolean;
    tableProps?: TableProps;
    tableUpdate?: Function;
    with?: string[];
    withCount?: string[];
    tableContainerProps?: TableContainerProps;
}
export interface IGPTableColumn {
    cellProps?: TableCellProps;
    field: string;
    headName?: string | React.ReactElement;
    onChange?: Function;
    renderCell?: (item: any, key: any, idx: number) => any;
    renderHead?: (item: any) => any;
    initSortDirection?: IGPTableSortDirections;
    sortable?: boolean;
    width?: number;
    description?: string;
}

export interface IGPTableSelection {
    id: string;
    selected: [];
}

export interface ITableFilterSettings {
    open: boolean;
    total: number;
}

const GuiTable: FC<IGPTable> = ({
    calculateHeight = true,
    calculateWidth = false,
    stickyHeader = true,
    tableProps = {},
    ...props
}) => {
    const tableRef = useRef<HTMLElement>();
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState(calculateWidth ? 0 : "100%");

    const renderFooter = () => {
        if (!!props.renderFooter) {
            return props.renderFooter();
        }
    };
    const renderHeader = () => {
        if (!!props.renderHeader) {
            return props.renderHeader();
        }
    };
    useEffect(() => {
        function resizeTable() {
            if (calculateWidth) {
                setWidth(0);
            }

            setTimeout(() => {
                if (calculateHeight) {
                    if (typeof calculateHeight === "function") {
                        setHeight(calculateHeight(tableRef.current));
                    } else if (tableRef.current?.offsetTop) {
                        const bottomPagePadding = 16;
                        let pagination = 0;
                        if (props.pagination) {
                            pagination = 40;
                        }

                        setHeight(
                            tableRef.current.offsetTop +
                                bottomPagePadding +
                                pagination
                        );
                    }
                }

                if (calculateWidth) {
                    const element = tableRef.current?.parentElement as Element;
                    let styles = getComputedStyle(element);

                    setWidth(
                        element.clientWidth -
                            parseFloat(styles.paddingLeft) -
                            parseFloat(styles.paddingRight)
                    );
                }
            }, 200);
        }

        if (calculateWidth || calculateHeight) {
            resizeTable();
            window.addEventListener("resize", resizeTable);
        }

        return () => window.removeEventListener("resize", resizeTable);
        // eslint-disable-next-line
    }, [calculateWidth, calculateHeight, props.filters]);

    let rowsLength = !!props.rows ? props.rows.length : 0;
    let tableContainerSX: SX = {};
    if (height > 0) {
        tableContainerSX.minHeight = "100px";
        tableContainerSX.maxHeight = `calc(100vh - ${height}px)`;
    }

    return (
        <Box ref={tableRef} sx={{ maxWidth: "100%", width }}>
            <Paper
                sx={{ width: "100%", overflow: "hidden", position: "relative" }}
            >
                <TableLoader loading={props.loading} />

                <TableContainer
                    {...props.tableContainerProps}
                    sx={{
                        ...props.tableContainerProps?.sx,
                        ...tableContainerSX,
                    }}
                >
                    <Table
                        id={props.id}
                        {...(stickyHeader && { stickyHeader: true })}
                        {...tableProps}
                        sx={{
                            "& .MuiTableRow-hover:hover td": {
                                backgroundColor: "#ebebeb",
                            },
                            "& .MuiTableCell-root": {
                                px: { xs: 1, sm: 2 },
                            },
                            ...tableProps?.sx,
                        }}
                    >
                        {props.renderHeader ? (
                            renderHeader()
                        ) : (
                            <TableHead {...props} />
                        )}
                        <TableBody {...props} />
                        {renderFooter()}
                    </Table>
                </TableContainer>

                <TableNoData rows={rowsLength} />
            </Paper>

            {!!props.pagination && (
                <TablePagination
                    rows={rowsLength}
                    pagination={props.pagination}
                    tableUpdate={props.tableUpdate}
                />
            )}
        </Box>
    );
};

export default GuiTable;
