import { EventModel, IDuplicate } from "./interfaces";
import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const eventsCreate = (data: EventModel, onResponse?: Function) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "eventsCreate",
        { data },
        onResponse
    );

export const eventsDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "eventsDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const eventsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "eventsFetch",
        {
            config,
        }
    );

export const eventsFetchAll = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_ALL_REQUEST,
        types.FETCH_ALL_FAILED,
        types.FETCH_ALL_SUCCESS,
        "eventsFetchAll",
        {
            config,
        }
    );

export const eventsGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "eventsGet",
        {
            config,
            url: {
                id,
            },
        }
    );

export const eventsTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const eventsUpdate = (
    id: number,
    data: EventModel,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "eventsUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const eventsDuplicate = (id: number, data: IDuplicate, onResponse?: Function) =>
    request(
        types.DUPLICATE_REQUEST,
        types.DUPLICATE_FAILED,
        types.DUPLICATE_SUCCESS,
        "eventsDuplicate",
        {
            data,
            url: {
                id,
            },
        },
        onResponse
    );
