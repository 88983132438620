import { BookingModel, BookingsSendEmailModel, IRefundData } from "./interfaces";
import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const bookingsCreate = (data: BookingModel, onResponse?: Function) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "bookingsCreate",
        { data },
        onResponse
    );

export const bookingsSendEmail = (
    id: string,
    data: BookingsSendEmailModel,
    onResponse?: Function
) =>
    request(
        types.SEND_EMAIL_REQUEST,
        types.SEND_EMAIL_FAILED,
        types.SEND_EMAIL_SUCCESS,
        "bookingsSendEmail",
        {
            data,
            url: {
                id,
            },
        },
        onResponse
    );

export const bookingsDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "bookingsDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const bookingsExport = (
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.EXPORT_REQUEST,
        types.EXPORT_FAILED,
        types.EXPORT_SUCCESS,
        "bookingsExport",
        {
            config,
        },
        onResponse
    );

export const bookingsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "bookingsFetch",
        {
            config,
        }
    );

export const bookingsFetchAll = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_ALL_REQUEST,
        types.FETCH_ALL_FAILED,
        types.FETCH_ALL_SUCCESS,
        "bookingsFetchAll",
        {
            config,
        }
    );

export const bookingsGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "bookingsGet",
        {
            config,
            url: {
                id,
            },
        }
    );

export const bookingsTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const bookingsUpdate = (
    id: number,
    data: BookingModel,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "bookingsUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const bookingsRefund = (
    id: number,
    data: IRefundData,
    onResponse?: Function
) => {
    return request(
        types.REFUND_REQUEST,
        types.REFUND_FAILED,
        types.REFUND_SUCCESS,
        "bookingsRefund",
        { data, url: { id } },
        onResponse
    );
};

export const bookingsRefundAll = (id: number, onResponse?: Function) => {
    return request(
        types.REFUND_ALL_REQUEST,
        types.REFUND_ALL_FAILED,
        types.REFUND_ALL_SUCCESS,
        "bookingsRefundAll",
        { url: { id } },
        onResponse
    );
};
