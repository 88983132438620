import FormContext from "./FormContext";
import { getValue } from "./helpers";
import TextField from "./TextField";
import currency from "currency.js";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { IGPTextField } from "./TextField";

interface IGPPrice extends IGPTextField {}

function GPPrice(props: IGPPrice) {
    const {
        id,
        onChange,
        value,
        onBlur,
        errorText = "",
        helperText = "",
        ...restProps
    } = props;

    const context = useContext(FormContext);

    let inputValue = getValue(id, context, value);
    const [isFocused, setFocus] = useState(false);
    const [val, setVal] = useState(
        currency(inputValue, {
            fromCents: true,
            symbol: "",
            separator: isFocused ? "" : " ",
        }).format()
    );

    useEffect(() => {
        if (!isFocused) {
            setVal(
                currency(inputValue, {
                    fromCents: true,
                    symbol: "",
                    separator: " ",
                }).format()
            );
        }
    }, [inputValue, isFocused]);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setVal(currency(val, { symbol: "", separator: " " }).format());
        setFocus(false);

        if (!!onBlur) {
            onBlur(e);
        }
    };

    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setVal(currency(val, { symbol: "", separator: "" }).format());
        setFocus(true);
    };

    const onChangeValue = ({ value }: { value: any }) => {
        const targetValue = value.replace(",", ".");

        if (
            targetValue.length === 0 ||
            /^(\d{0,16})([.]\d{0,2})?$/.test(targetValue)
        ) {
            const intValue = currency(targetValue).intValue;

            if (!!onChange) {
                onChange({ id, value: intValue }, context);
            } else {
                context?.onChange({ id, value: intValue });
            }

            setVal(targetValue);
        }
    };

    return (
        <TextField
            {...restProps}
            errorText={errorText}
            helperText={helperText}
            id={id}
            onBlur={handleBlur}
            onChange={onChangeValue}
            onFocus={onFocus}
            value={val}
        />
    );
}

GPPrice.propTypes = {
    errorText: PropTypes.string,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.number,
};

export default GPPrice;
