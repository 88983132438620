import { FETCH_SUCCESS, TABLE_UPDATE } from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { IGPTable } from "ui/Table/Table";
import { AnyAction } from "redux";
import { MANAGE_TABLE } from "app/ManageTables/types";

function PermissionsTableReducer(
    state: IGPTable = {
        id: "PermissionsTable",
        columns: [],
        availableColumns: [],
        filters: {},
        filterSettings: {
            open: false,
            total: 0,
        },
        pagination: {
            page: 1,
            per_page: 20,
            isLastPage: true,
            total: 0,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        sort: { name: "asc" },
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state;

        case TABLE_UPDATE:
            return tableUpdate(state, action);

        default:
            return state;
    }
}

export default PermissionsTableReducer;
