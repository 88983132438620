import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import {
    APP_HANDLE_ERROR,
    APP_SETTINGS_REQUEST,
    APP_SETTINGS_FAILED,
    APP_SETTINGS_SUCCESS,
    CONFIRMATION_CONFIRMED,
    CONFIRMATION_UNCONFIRMED,
    MODAL_HIDE,
    MODAL_SHOW,
    SNACKBAR_HIDE,
    SNACKBAR_SHOW,
    SIDE_NAV_TYPE,
    APP_TRANSLATE_REQUEST,
    APP_TRANSLATE_FAILED,
    APP_TRANSLATE_SUCCESS,
    LOCALE_REQUEST,
    LOCALE_FAILED,
    LOCALE_SUCCESS,
    SETTINGS_UPDATE_REQUEST,
    SETTINGS_UPDATE_FAILED,
    SETTINGS_UPDATE_SUCCESS,
    CHANGE_LANG,
} from "types";

export const confirmationConfirmed = () => ({
    type: CONFIRMATION_CONFIRMED,
});
export const confirmationUnconfirmed = () => ({
    type: CONFIRMATION_UNCONFIRMED,
});

export const handleError = (error: any) => ({
    type: APP_HANDLE_ERROR,
    error,
});

export const modalHide = (id: string, props?: any) => ({
    type: MODAL_HIDE,
    id,
    props,
});
export const modalShow = (id: string, props?: any) => ({
    type: MODAL_SHOW,
    id,
    props,
});

export const snackbarHide = () => ({
    type: SNACKBAR_HIDE,
});

export const snackbarShow = (data: any, closeAfter = 6000, type = "alert") => {
    return {
        type: SNACKBAR_SHOW,
        data: {
            data,
            closeAfter,
            type,
        },
    };
};

export const changeSideNavType = (type: string) => ({
    type: SIDE_NAV_TYPE,
    data: type,
});

export const appSettings = () =>
    request(APP_SETTINGS_REQUEST, APP_SETTINGS_FAILED, APP_SETTINGS_SUCCESS);

export const appSettingsUpdate = (data: any, onResponse?: Function) => {
    return request(
        SETTINGS_UPDATE_REQUEST,
        SETTINGS_UPDATE_FAILED,
        SETTINGS_UPDATE_SUCCESS,
        "appSettingsUpdate",
        { data },
        onResponse
    );
};

export const appTranslate = (config?: AxiosRequestConfig, data?: any) =>
    request(
        APP_TRANSLATE_REQUEST,
        APP_TRANSLATE_FAILED,
        APP_TRANSLATE_SUCCESS,
        "appTranslate",
        {
            config,
            data,
        }
    );

export const changeLocale = (config?: AxiosRequestConfig) =>
    request(LOCALE_REQUEST, LOCALE_FAILED, LOCALE_SUCCESS, "changeLocale", {
        config,
    });

export const changeLang = (data: any) => ({
    type: CHANGE_LANG,
    data,
});
