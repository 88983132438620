import trans from "helpers/trans";
import { FC } from "react";
import { Button, Modal, ModalActions, ModalContent } from "ui";
import { useDispatch } from "react-redux";
import {
    confirmationConfirmed,
    confirmationUnconfirmed,
    modalHide,
} from "../actions";
import { IConfirmationModal } from "../interfaces";

const ConfirmationModal: FC<IConfirmationModal> = ({
    agreeButton,
    disagreeButton,
    message = trans("event.app.modal.confirmationModal.message"),
    onClickAgree,
    onClickDisagree,
    showDisagreeButton = true,
}) => {
    const dispatch = useDispatch();

    const handleClickAgree = () => {
        if (!!onClickAgree) {
            onClickAgree();
        }
        dispatch(confirmationConfirmed());
        dispatch(modalHide("ConfirmationModal"));
    };

    const handleClickDisagree = () => {
        if (!!onClickDisagree) {
            onClickDisagree();
        }
        dispatch(confirmationUnconfirmed());
        dispatch(modalHide("ConfirmationModal"));
    };

    const onClose = (
        event: Event | React.SyntheticEvent<any, Event>,
        reason: string
    ) => {
        return false;
    };

    return (
        <Modal maxWidth="xs" fullWidth onClose={onClose} open>
            <ModalContent>{message}</ModalContent>
            <ModalActions>
                {showDisagreeButton && (
                    <Button
                        onClick={handleClickDisagree}
                        variant="text"
                        color="secondary"
                        sx={{ fontWeight: 500 }}
                    >
                        {disagreeButton
                            ? disagreeButton
                            : trans(
                                  "event.app.modal.confirmationModal.button.disagree"
                              )}
                    </Button>
                )}
                <Button
                    onClick={handleClickAgree}
                    variant="text"
                    color="secondary"
                >
                    {agreeButton
                        ? agreeButton
                        : trans("event.app.modal.confirmationModal.button.agree")}
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default ConfirmationModal;
