import { Box, Icon, FormHelperText, FormControl } from "@mui/material";
import TextField, { IGPTextField } from "./TextField";
import { getHelperText, getValue } from "./helpers";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "ui";
import FormContext from "./FormContext";

interface IGPFile extends IGPTextField {
    accept?: string;
    preview?: string;
}

function GPFile({
    accept,
    id,
    fullWidth,
    title,
    disabled,
    onChange,
    preview,
    sx,
    value,
    ...restProps
}: IGPFile) {
    const { errorText = "", helperText = "" } = restProps;
    const context = useContext(FormContext);
    const fileValue = getValue(id, context, value);

    const fileInputRef = useRef<any>(null);
    const [previewImg, setPreviewImg] = useState<string | undefined>(preview);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (fileInputRef.current.value && !fileValue) {
            fileInputRef.current.value = "";
            setInputValue("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileValue]);

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    const handleChange = () => {
        if (fileInputRef?.current?.files?.[0]) {
            let reader: any = new FileReader();
            reader.readAsDataURL(fileInputRef.current.files[0]);

            reader.onload = () => {
                if (fileInputRef.current.files[0].type.includes("image")) {
                    setPreviewImg(reader.result);
                } else {
                    setPreviewImg(undefined);
                }

                const result = reader.result
                    .replace("data:", "")
                    .replace(/^.+,/, "");

                if (!!onChange) {
                    onChange(
                        {
                            id,
                            value: result,
                        },
                        context
                    );
                } else {
                    context?.onChange({
                        id,
                        value: result,
                    });
                }

                setInputValue(fileInputRef.current.value);
                fileInputRef.current.value = "";
            };
        } else {
            setInputValue("");
            setPreviewImg(undefined);
        }
    };

    const renderPreview = () => {
        let previewContent = null;

        if (inputValue.length === 0) {
            if (!!preview) {
                previewContent = (
                    <Box
                        component="img"
                        sx={{
                            maxHeight: 250,
                            maxWidth: 250,
                        }}
                        src={previewImg}
                    />
                );
            } else {
                return null;
            }
        } else {
            if (previewImg) {
                previewContent = (
                    <Box
                        component="img"
                        sx={{
                            maxHeight: 250,
                            maxWidth: 250,
                        }}
                        src={previewImg}
                    />
                );
            } else {
                previewContent = (
                    <Icon sx={{ color: "grey.500", fontSize: 50 }}>
                        description
                    </Icon>
                );
            }
        }

        return (
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    my: 2,
                }}
            >
                {previewContent}
            </Box>
        );
    };

    return (
        <FormControl fullWidth={fullWidth} sx={sx} error={isError}>
            {renderPreview()}

            <Button component="label" fullWidth disabled={disabled}>
                {title}
                <TextField
                    {...restProps}
                    id={`${id}-file`}
                    inputProps={{ accept }}
                    inputRef={fileInputRef}
                    onChange={handleChange}
                    sx={{ display: "none" }}
                    type="file"
                />
            </Button>

            {inputHelperText.length > 0 && (
                <FormHelperText>{inputHelperText}</FormHelperText>
            )}
        </FormControl>
    );
}

export default GPFile;
