import { webEventsBookingsCreate } from "../actions";
import TicketFirstStep from "../components/TicketFirstStep";
import TicketSecondStep from "../components/TicketSecondStep";

import {
    Box,
    Icon,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import useRequest from "api/useRequest";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, LoadingButton, Modal, ModalActions } from "ui";

import { isEmpty } from "lodash";
import { IWebTicket, IWebUpsale } from "../interfaces";

interface ITicketsModal {
    onCloseModal: Function;
    uuid: string;
}

const TicketModal: FC<ITicketsModal> = ({ onCloseModal, uuid }) => {
    const navigate = useNavigate();
    const { uuid: webUUID } = useSelector((state: any) => state.web);
    const {
        "general-currency": currency,
        "general-max_tickets": maxTickets,
        "general-primary_color": primaryColor,
    } = useSelector((state: any) => state.web.settings.location_settings);

    const [guests, setGuests] = useState<number>(0);
    const [view, setView] = useState<number>(0);
    const [eventId, setEventId] = useState<any>(null);
    const [selectedTicket, setSelectedTicket] = useState<any>({});
    const [selectedUpsales, setSelectedUpsale] = useState<any>([]);

    const {
        isLoading: isLoadingBooking,
        message,
        request: requestBooking,
        status,
    } = useRequest();

    const totalPrice = useMemo(() => {
        const upsalePrice = selectedUpsales.reduce(
            (acc: number, item: IWebUpsale) =>
                acc + item.price * (item.quantity || 1),
            0
        );
        const ticketPrice = selectedTicket?.price || 0;
        return upsalePrice + guests * ticketPrice;
    }, [guests, selectedTicket, selectedUpsales]);

    const smallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("tablet")
    );

    const handleBack = () => {
        setView(0);
        setSelectedTicket({});
        setSelectedUpsale([]);
        setEventId(null);
    };
    const handleBuy = (itemUUID: string, itemId: number) => {
        setView(1);
        setEventId({uuid: itemUUID, id: itemId});
    };

    // const handleGroupTickets = () => {
    //     onCloseModal();
    //     navigate(`tickets/${uuid}/event/${id}/group-tickets`);
    // };

    const handleNext = () => {
        const preparedTicket = { id: null, event_ticket_id: selectedTicket.id };
        const preparedTickets = Array(guests).fill(preparedTicket);
        const data = {
            event_id: eventId.id,
            event_upsales: selectedUpsales.map((item: IWebUpsale) => ({
                event_upsale_id: item.pivot.id,
                id: null,
                quantity: item.quantity,
            })),
            tickets: selectedTicket.id ? preparedTickets : [],
        };
        requestBooking(
            webEventsBookingsCreate(data, (response: AxiosResponse) => {
                if (response.status === 204) {
                    navigate(`tickets/${webUUID}/basket`);
                    onCloseModal();
                }
            })
        );
    };

    const handleSelectTicket = (ticket: IWebTicket) => {
        setSelectedTicket(ticket);
    };

    const handleSelectUpsale = (checked: boolean, upsale: IWebUpsale) => {
        setSelectedUpsale((prev: IWebUpsale[]) => {
            const exists = prev.some((item) => item.id === upsale.id);
            if (checked) {
                if (exists) {
                    return prev.map((item) =>
                        item.id === upsale.id ? upsale : item
                    );
                } else {
                    return [...prev, upsale];
                }
            } else {
                return prev.filter((item) => item.id !== upsale.id);
            }
        });
    };

    const renderGuests = () => {
        return (
            <Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <IconButton
                        onClick={() => setGuests((prev) => prev - 1)}
                        disabled={guests === 0}
                        sx={{
                            ":hover": {
                                boxShadow: "none",
                                background: "#B97964",
                            },
                            width: { xs: 24, tablet: 28 },
                            height: { xs: 24, tablet: 28 },
                            background: "#B97964",
                            color: "#fff",
                            "&.Mui-disabled": {
                                backgroundColor: "rgb(185 121 100 / 0.26)",
                            },
                        }}
                    >
                        <Icon sx={{ fontSize: { xs: 16, tablet: 18 } }}>
                            remove
                        </Icon>
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: { xs: 14, tablet: 16 },
                            fontWeight: 500,
                            px: 2,
                            fontFamily: "'Noto Sans', sans-serif",
                            color: "#02263A",
                        }}
                    >
                        {trans(
                            guests !== 1
                                ? "event.webEvent.modal.ticketModal.guests"
                                : "event.webEvent.modal.ticketModal.guest",
                            {
                                guests,
                            }
                        )}
                    </Typography>

                    <IconButton
                        onClick={() => setGuests((prev) => prev + 1)}
                        disabled={guests === maxTickets}
                        sx={{
                            ":hover": {
                                boxShadow: "none",
                                background: "#B97964",
                            },
                            width: { xs: 24, tablet: 28 },
                            height: { xs: 24, tablet: 28 },
                            background: "#B97964",
                            color: "#fff",
                            "&.Mui-disabled": {
                                backgroundColor: "rgb(185 121 100 / 0.26)",
                            },
                        }}
                    >
                        <Icon sx={{ fontSize: { xs: 16, tablet: 18 } }}>
                            add
                        </Icon>
                    </IconButton>
                </Box>
            </Box>
        );
    };

    return (
        <Modal
            maxWidth="md"
            fullWidth
            open
            fullScreen={smallScreen}
            scroll="paper"
            {...(!smallScreen && {
                sx: {
                    ".MuiDialog-container": {
                        justifyContent: "flex-end",
                    },
                    ".MuiPaper-root": {
                        margin: "0",
                        width: 630,
                        height: "100%",
                        maxHeight: "100%",
                        borderRadius: 0,
                    },
                },
            })}
        >
            {view === 0 ? (
                <TicketFirstStep
                    uuid={uuid}
                    onBuy={handleBuy}
                    children={renderGuests()}
                    guests={guests}
                    onCloseModal={onCloseModal}
                />
            ) : (
                <TicketSecondStep
                    guests={guests}
                    uuid={eventId.uuid}
                    onSelectTicket={handleSelectTicket}
                    onSelectUpsale={handleSelectUpsale}
                    children={renderGuests()}
                    onCloseModal={onCloseModal}
                    bookingMessage={message}
                    bookingStatus={status}
                    selectedUpsales={selectedUpsales}
                />
            )}
            {view === 1 && (
                <ModalActions sx={{ pt: 3, px: 4, pb: 3.75 }}>
                    <Box
                        display="flex"
                        width="100%"
                        textAlign="right"
                        flexDirection="column"
                        justifyContent="center"
                        sx={{
                            gap: { xs: 2, tablet: 3 },
                        }}
                    >
                        {/* {view === 0 && (
                        <>
                            {" "}
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontSize: { xs: 13, tablet: 18 },
                                    fontWeight: 500,
                                }}
                            >
                                {trans(
                                    "event.webEvent.modal.ticketModal.moreTickets",
                                    { maxTickets }
                                )}
                            </Typography>
                            <Button
                                onClick={handleGroupTickets}
                                sx={{
                                    ":hover": {
                                        boxShadow: "none",
                                        background: "#FFF8F8",
                                    },
                                    fontSize: { xs: 14, tablet: 18 },
                                    borderRadius: 1,
                                    boxShadow: "none",
                                    padding: "6px 16px",
                                    background: "#FFF8F8",
                                    height: 48,
                                    display: "flex",
                                    width: "100%",
                                    color: "#02263A",
                                    flex: 1,
                                    textAlign: "center",
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 500,
                                }}
                            >
                                {trans(
                                    "event.webEvent.modal.ticketModal.groupTickets"
                                )}
                            </Button>
                        </>
                    )} */}
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    p: 0,
                                    justifyContent: "space-between",
                                    my: 0,
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                    flexDirection: "row",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: {
                                            xs: "'Noto Sans', sans-serif",
                                            tablet: "'Lato', sans-serif",
                                        },
                                        fontSize: 18,
                                        fontWeight: { xs: 500, tablet: 700 },
                                        color: "#495366",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {trans(
                                        "event.webEvent.modal.ticketModal.sum"
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: {
                                            xs: "'Noto Sans', sans-serif",
                                            tablet: "'Lato', sans-serif",
                                        },
                                        fontSize: 18,
                                        fontWeight: { xs: 500, tablet: 700 },
                                        color: "#495366",
                                    }}
                                >
                                    {totalPrice.price(currency, "left")}
                                </Typography>
                            </Box>
                            <Stack
                                direction="row"
                                sx={{
                                    marginLeft: "0!important",
                                    width: "100%",
                                    gap: { xs: 1, tablet: 2 },
                                    p: 0,
                                }}
                            >
                                <Button
                                    variant="text"
                                    onClick={handleBack}
                                    sx={{
                                        ":hover": {
                                            boxShadow: "none",
                                            background: "transparent",
                                        },
                                        fontSize: { xs: 18, tablet: 20 },
                                        borderRadius: 1,
                                        boxShadow: "none",
                                        padding: 0,
                                        background: "transparent",
                                        height: 48,
                                        color: "#777777",
                                        textAlign: "center",
                                        fontFamily:
                                            "'Source Sans 3', sans-serif",
                                        fontWeight: 500,
                                    }}
                                >
                                    {trans("global.button.back")}
                                </Button>
                                <LoadingButton
                                    onClick={handleNext}
                                    loading={isLoadingBooking}
                                    disabled={
                                        isEmpty(selectedTicket) || guests === 0
                                    }
                                    sx={{
                                        ":hover": {
                                            background: primaryColor,
                                            boxShadow: "none",
                                        },
                                        background: primaryColor,
                                        fontFamily:
                                            "'Source Sans 3', sans-serif",
                                        fontWeight: 500,
                                        fontSize: { xs: 18, tablet: 20 },
                                        borderRadius: 1,
                                        boxShadow: "none",
                                        padding: "0 16px",
                                        height: 48,
                                        display: "flex",
                                        flex: 1,
                                        m: "auto",
                                        color: (theme) =>
                                            theme.palette.getContrastText(
                                                primaryColor
                                            ),
                                    }}
                                >
                                    {trans("global.button.next")}
                                </LoadingButton>
                            </Stack>
                        </>
                    </Box>
                </ModalActions>
            )}
        </Modal>
    );
};

export default TicketModal;
