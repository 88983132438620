import api from "./api";
import types from "./types";
import { manageTable, manageTableGet } from "./actions";
import { request } from "api/apiSaga";
import { modalHide, snackbarShow } from "app/App/actions";
import { prepareColumns } from "app/ManageTables/prepareColumns";
import trans from "helpers/trans";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";

function* manageGet(action: AnyAction) {
    const response: AxiosResponse = yield call(request, api.get, action);
    if (response.status === 200) {
        yield put(manageTable(prepareColumns(response.data.data)));
    }
}

function* manageDelete(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.delete,
        action,
        "delete"
    );

    if (response.status === 204) {
        yield put(modalHide("TableModal"));
        yield put(
            snackbarShow({
                content: trans("event.manageTables.saga.content.destroy"),
            })
        );
        yield put(manageTableGet(action.axiosConfig.url.id));
    }
}

function* manageUpdate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.update,
        action,
        "put"
    );
    if (response.status === 200) {
        yield put(modalHide("TableModal"));
        yield put(
            snackbarShow({
                content: trans("event.manageTables.saga.content.update"),
            })
        );
        yield put(manageTable(prepareColumns(response.data.data)));
    }
}

export default function* saga() {
    yield takeEvery(types.MANAGE_TABLE_REQUEST, manageGet);
    yield takeLatest(types.MANAGE_TABLE_DELETE_REQUEST, manageDelete);
    yield takeLatest(types.MANAGE_TABLE_UPDATE_REQUEST, manageUpdate);
}
