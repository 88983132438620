import { LoadingButton, LoadingButtonProps } from "@mui/lab";

import { styled } from "@mui/material/styles";

interface IGPUILoadingButton extends LoadingButtonProps {}

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    textTransform: "none",
})) as typeof LoadingButton;

function GPUILoadingButton(props: IGPUILoadingButton) {
    const { children, variant = "contained", ...restProps } = props;

    return (
        <StyledLoadingButton variant={variant} {...restProps}>
            {children}
        </StyledLoadingButton>
    );
}

export default GPUILoadingButton;
