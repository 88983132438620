import { web } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    fetch: (config: AxiosRequestConfig, url: RequestUrlId) =>
        web.get(`/events`, config),
    filters: (config: AxiosRequestConfig) => web.get(`/events/filters`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        web.get(`events/${url.id}`, config),

    checkout: (data: object, config: AxiosRequestConfig) =>
        web.put(`/bookings/checkout`, data, config),
    getBooking: (config: AxiosRequestConfig) => web.get(`/bookings`, config),
    fetchPayments: (config: AxiosRequestConfig) =>
        web.get(`bookings/methods`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        web.post(`/bookings`, data, config),

    settings: (config: AxiosRequestConfig, url: RequestUrlId) =>
        web.get(`/settings/${url.id}`, config),
};

export default requests;
