import { forwardRef, useContext, useEffect, useState } from "react";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { FormControl, FormHelperText } from "@mui/material";
import updateLocale from "dayjs/plugin/updateLocale";
import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";

export interface IGPDateTimePicker
    extends Omit<DateTimePickerProps<any>, "onChange"> {
    errorText?: string;
    id: string;
    onChange?: Function;
    regex?: string;
    helperText?: string;
    fullWidth?: boolean;
    format?: string;
}

const GPDateTimePicker = forwardRef<any, IGPDateTimePicker>((props, ref) => {
    const {
        errorText = "",
        format = "DD/MM/YYYY HH:mm",
        fullWidth,
        helperText = "",
        id,
        onChange,
        sx,
        value,
        ...restProps
    } = props;
    const context = useContext(FormContext);
    const lang = document.documentElement.lang;
    const dateValue = getValue(id, context, value);
    const [val, setValue] = useState<any>(
        getValue(id, context, value)
            ? dayjs(getValue(id, context, value), format)
            : null
    );

    dayjs.extend(updateLocale);
    dayjs.updateLocale(lang, {
        weekStart: 1,
    });

    useEffect(() => {
        setValue(dateValue ? dayjs(dateValue, format) : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateValue]);

    const onChangeValue = (value: any) => {
        const formattedValue = value ? dayjs(value).format(format) : null;
        if (!!onChange) {
            onChange({ id, value: formattedValue }, context);
        } else {
            context?.onChange({ id, value: formattedValue });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
            <FormControl fullWidth={fullWidth} sx={sx} error={isError}>
                <DateTimePicker
                    {...restProps}
                    ampm={false}
                    sx={{
                        height: 40,
                        width: "100%",

                        pr: 0,
                        ".MuiInputBase-root": {
                            height: 40,
                            width: "100%",
                            // fontSize: 13,
                        },
                        ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: isError ? "#d32f2f" : "#36845B",
                            },
                        ".MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: isError
                                    ? "#d32f2f"
                                    : "rgba(0, 0, 0, 0.23)",
                            },
                        ".MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: isError
                                    ? "#d32f2f"
                                    : "rgba(0, 0, 0, 0.87)",
                            },
                        ".MuiFormLabel-root, .MuiFormLabel-root.Mui-focused": {
                            color: isError ? "#d32f2f" : "#36845B",
                        },
                        ".MuiFormLabel-root:not(.Mui-focused)": {
                            color: isError ? "#d32f2f" : "rgba(0, 0, 0, 0.6)",
                        },
                    }}
                    onChange={onChangeValue}
                    value={val}
                    inputRef={ref}
                    format={format}
                />
                {inputHelperText.length > 0 && (
                    <FormHelperText>{inputHelperText}</FormHelperText>
                )}
            </FormControl>
        </LocalizationProvider>
    );
});

export default GPDateTimePicker;
