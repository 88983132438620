import useRequest from "api/useRequest";
import { Box, Checkbox, Chip, Typography } from "@mui/material";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    QuantityField,
    RequestMessage,
} from "ui";
import { BookingModel, IItem, IRefund } from "../interfaces";
import { get } from "lodash";
import { bookingsRefund, bookingsRefundAll } from "../actions";
import { AxiosResponse } from "axios";

interface IBookingsRefundModal {
    id: string;
    reload: Function;
    data: BookingModel;
}

const BookingsRefundModal: FC<IBookingsRefundModal> = ({
    data,
    id,
    reload,
}) => {
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);
    const [partialSum, setPartialSum] = useState<number>(0);
    const {
        isLoading: refundAllIsLoading,
        message: refundAllMessage,
        request: refundAllRequest,
        status: refundAllStatus,
        isError: refundAllIsError,
        errors: refundAllErrors,
    } = useRequest();
    const { isLoading, message, request, status, isError, errors } =
        useRequest();

    const handleCloseModal = () => {
        dispatch(modalHide("BookingsRefundModal"));
    };

    const handleCheck = (
        event: React.ChangeEvent<HTMLInputElement>,
        context: any,
        ticket: any
    ) => {
        if (event.target.checked) {
            context.onChange({
                id: "items",
                value: [
                    ...get(context.data, "items", []),
                    { id: ticket.id, quantity: 1 },
                ],
            });
        } else {
            context.onChange({
                id: "items",
                value: get(context.data, "items", []).filter(
                    (item: IRefund) => item.id !== ticket.id
                ),
            });
        }
    };

    const handleUpdateQuantity = (
        e: { id: string; value: string },
        upsale: IItem,
        context: any
    ) => {
        const findUpsale = get(context.data, "items", []).find(
            (item: IItem) => item.id === upsale.id
        );
        if (findUpsale) {
            context.onChange({
                id: "items",
                value:
                    +e.value === 0
                        ? get(context.data, "items", []).filter(
                              (item: IItem) => item.id !== findUpsale?.id
                          )
                        : get(context.data, "items", []).map((item: IItem) => {
                              if (item.id === findUpsale.id)
                                  return { ...item, quantity: +e.value };
                              else return item;
                          }),
            });
        } else {
            context.onChange({
                id: "items",
                value: [
                    ...get(context.data, "items", []),
                    {
                        id: upsale.id,
                        quantity: +e.value,
                    },
                ],
            });
        }
    };

    const handleRefund = () => {
        const dataToSend = formRef.current?.getData();
        if (dataToSend)
            request(
                bookingsRefund(+id, dataToSend, (response: AxiosResponse) => {
                    if (response.status === 204) {
                        handleCloseModal();
                        reload();
                    }
                })
            );
    };
    const handleRefundAll = () => {
        refundAllRequest(
            bookingsRefundAll(+id, (response: AxiosResponse) => {
                if (response.status === 204) {
                    handleCloseModal();
                    reload();
                }
            })
        );
    };

    const handleChangeForm = (current: any) => {
        const sum = current.items.reduce((acc: number, current: IItem) => {
            const findUpsale = (data.items as IItem[]).find(
                (item: IItem) => item.id === current.id
            );
            const maxUpsale = findUpsale
                ? findUpsale.quantity - findUpsale.refunded_quantity
                : 0;

            const calcQuantity =
                findUpsale && current.quantity > maxUpsale
                    ? maxUpsale
                    : current.quantity;
            return acc + calcQuantity * (findUpsale?.unit_gross || 0);
        }, 0);
        setPartialSum(sum);
    };
    return (
        <Modal open fullWidth maxWidth="sm">
            <ModalTitle onClose={handleCloseModal}>
                {trans("event.bookings.modal.bookingRefundModal.title.refund")}
            </ModalTitle>
            <ModalContent>
                {isError && (
                    <RequestMessage
                        message={message}
                        status={status}
                        sx={{
                            mb: 1,
                        }}
                    />
                )}
                {refundAllIsError && (
                    <RequestMessage
                        message={refundAllMessage}
                        status={refundAllStatus}
                        sx={{
                            mb: 1,
                        }}
                    />
                )}
                <Form
                    ref={formRef}
                    data={{}}
                    fields={{
                        items: { default: [] },
                    }}
                    errors={
                        isError
                            ? errors
                            : refundAllIsError
                            ? refundAllErrors
                            : {}
                    }
                    onSubmit={() => {}}
                    unsaved={false}
                    onChanged={handleChangeForm}
                >
                    {(context: any) => {
                        const upsales = data.items?.filter(
                            (upsale: IItem) =>
                                upsale.sellable_type.includes("Upsale") &&
                                upsale.booking_payment_id &&
                                upsale.quantity - upsale.refunded_quantity !== 0
                        );
                        const tickets = data.items?.filter(
                            (item: IItem) =>
                                item.sellable_type.includes("Ticket") &&
                                item.booking_payment_id &&
                                item.quantity - item.refunded_quantity !== 0
                        );
                        return (
                            <>
                                {tickets && tickets.length > 0 && (
                                    <Box
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 1.5,
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {trans(
                                                "event.bookings.modal.bookingRefundModal.title.tickets"
                                            )}
                                        </Typography>
                                        <Box
                                            display="flex"
                                            gap={0.5}
                                            flexDirection="column"
                                            sx={{ minWidth: 400 }}
                                        >
                                            {tickets.map((item: IItem) => {
                                                return (
                                                    <Box
                                                        key={`ticket-${item.id}`}
                                                        display="flex"
                                                        gap={1}
                                                        flexDirection="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        sx={{
                                                            wordBreak:
                                                                "break-word",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                maxWidth: 120,
                                                            }}
                                                        >
                                                            1x {item.name}
                                                        </Typography>
                                                        {item.sellable
                                                            ?.status && (
                                                            <Chip
                                                                label={
                                                                    item
                                                                        .sellable
                                                                        .status
                                                                }
                                                                size="small"
                                                                color={
                                                                    item
                                                                        .sellable
                                                                        .status ===
                                                                    "CANCELLED"
                                                                        ? "default"
                                                                        : item
                                                                              .sellable
                                                                              .status ===
                                                                          "USED"
                                                                        ? "warning"
                                                                        : "secondary"
                                                                }
                                                            />
                                                        )}
                                                        <Box
                                                            display="flex"
                                                            gap={1}
                                                            flexDirection="row"
                                                            alignItems="center"
                                                        >
                                                            <Typography>
                                                                {item.unit_gross.price(
                                                                    data.currency
                                                                )}
                                                            </Typography>
                                                            <Checkbox
                                                                id="items"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleCheck(
                                                                        event,
                                                                        context,
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                )}
                                {upsales && upsales.length > 0 && (
                                    <Box
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 1.5,
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {trans(
                                                "event.bookings.modal.bookingRefundModal.title.upsales"
                                            )}
                                        </Typography>
                                        <Box
                                            display="flex"
                                            gap={1}
                                            flexDirection="column"
                                            sx={{ minWidth: 400 }}
                                        >
                                            {upsales.map((upsale: IItem) => {
                                                const maxUpsale =
                                                    upsale.quantity -
                                                    upsale.refunded_quantity;
                                                const itemUpsale =
                                                    context.data.items.find(
                                                        (item: IRefund) =>
                                                            item.id ===
                                                            upsale.id
                                                    )?.quantity || 0;
                                                const upsaleQuantityToShow =
                                                    itemUpsale > maxUpsale
                                                        ? maxUpsale
                                                        : itemUpsale;
                                                return (
                                                    <Box
                                                        key={`upsale-${upsale.id}`}
                                                        display="flex"
                                                        gap={1}
                                                        flexDirection="row"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography>
                                                            {
                                                                upsaleQuantityToShow
                                                            }
                                                            /{maxUpsale}
                                                            {" x "}{" "}
                                                            {upsale.name}
                                                        </Typography>
                                                        <Box
                                                            display="flex"
                                                            gap={1}
                                                            flexDirection="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Typography>
                                                                {(
                                                                    upsaleQuantityToShow *
                                                                    upsale.unit_gross
                                                                ).price(
                                                                    data.currency
                                                                )}
                                                            </Typography>
                                                            <Box
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                sx={{
                                                                    width: "auto",
                                                                }}
                                                            >
                                                                <QuantityField
                                                                    id=""
                                                                    value={
                                                                        itemUpsale
                                                                    }
                                                                    maxValue={
                                                                        upsale.quantity -
                                                                        upsale.refunded_quantity
                                                                    }
                                                                    onChange={(e: {
                                                                        id: string;
                                                                        value: string;
                                                                    }) =>
                                                                        handleUpdateQuantity(
                                                                            e,
                                                                            upsale,
                                                                            context
                                                                        )
                                                                    }
                                                                    variant="outlined"
                                                                    size="small"
                                                                    float={
                                                                        false
                                                                    }
                                                                    sx={{
                                                                        ".MuiInputBase-root":
                                                                            {
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                padding: 0,
                                                                                width: 125,
                                                                            },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                )}
                            </>
                        );
                    }}
                </Form>
            </ModalContent>
            <ModalActions>
                <Box
                    display="flex"
                    width="100%"
                    textAlign="right"
                    justifyContent="space-between"
                    gap={1}
                >
                    <LoadingButton
                        onClick={handleRefundAll}
                        loading={refundAllIsLoading}
                        variant="outlined"
                    >
                        {trans(
                            "event.bookings.modal.bookingRefundModal.button.refundTotal",
                            {
                                sum: (data.total_gross || 0).price(
                                    data.currency
                                ),
                            }
                        )}
                    </LoadingButton>
                    <LoadingButton
                        onClick={handleRefund}
                        loading={isLoading}
                        variant="outlined"
                        disabled={
                            (formRef.current &&
                                formRef.current.getData().items.length === 0) ||
                            !formRef.current
                        }
                    >
                        {trans(
                            "event.bookings.modal.bookingRefundModal.button.partialRefund",
                            { sum: partialSum.price(data.currency) }
                        )}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};
export default BookingsRefundModal;
