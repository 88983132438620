import PaymentsTable from "./reducers//PaymentsTableReducer";
import PaymentsSaga from "./saga";

import { url as settingsUrl } from "app/Settings/config";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, configType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "payment.provider";
export const url = `${settingsUrl}/payments`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("PaymentsTable", PaymentsTable);
        store.injectSaga?.("PaymentsSaga", PaymentsSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Payments/pages/PaymentsIndexPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: <AsyncComponent path="Payments/pages/PaymentsCreatePage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${url}/:id/edit`,
            element: <AsyncComponent path="Payments/pages/PaymentsEditPage" />,
        });
    }
    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
