import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    sendEmail: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`/bookings/send/payment_email/${url.id}`, data, config),
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/bookings/${url.id}`, config),
    export: (config: AxiosRequestConfig) =>
        api.get(`/bookings/export`, {
            ...config,
            responseType: "blob",
        }),
    fetch: (config: AxiosRequestConfig) => api.get(`/bookings`, config),
    fetchAll: (config: AxiosRequestConfig) => api.get(`/bookings/all`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/bookings/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/bookings`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/bookings/${url.id}`, data, config),
    refund: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/bookings/refund/${url.id}`, data, config),
    refundAll: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/bookings/refund/${url.id}/all`, data, config),
};

export default requests;
