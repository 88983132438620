import { LanguageModel } from "app/Languages/interfaces";
import { ISubtranslation } from "app/Settings/interfaces";
import store from "rootStore";

const getSubtranslation = (
    data: ISubtranslation | null,
    isCurrentLang?: boolean
) => {
    const state = store.getState();
    const app = state.web ? "web" : "app";
    const defaultLang = state[app].settings.languages.find(
        (lang: LanguageModel) => lang.is_default
    )?.key;
    const currentLanguage = state.app.lang;

    const languages = state[app].settings.languages;
    const lang = document.documentElement.lang;
    const languageToUse = isCurrentLang
        ? currentLanguage
        : languages.find((item: LanguageModel) => item.key === lang)?.key ||
          defaultLang;
    return data ? data[`lang_${languageToUse}` as keyof typeof data] : "";
};

export default getSubtranslation;
