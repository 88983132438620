import { api, web } from "apiConnections";

const setCustomHeader = (
    header: string,
    value: string,
    isWeb: boolean = false
) => {
    if (header && value) {
        if (isWeb) {
            web.defaults.headers.common[header] = value;
            api.defaults.headers.common[header] = value;
        } else {
            api.defaults.headers.common[header] = value;
        }
    } else if (!value) {
        if (isWeb) {
            delete web.defaults.headers.common[header];
            delete api.defaults.headers.common[header];
        } else delete api.defaults.headers.common[header];
    }
};

export default setCustomHeader;
