import api from "./api"
import types from "./types";

import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import {AnyAction} from "redux"
import { call, takeLatest, put } from "redux-saga/effects";

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* create(action: AnyAction): any {
    const response = yield call(request, api.create, action, "post");

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("event.users.saga.content.store"),
            })
        );
    }
}

function* deleteUser(action: AnyAction): any {
    const response = yield call(request, api.delete, action, "delete");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("event.users.saga.content.destroy"),
            })
        );
    }
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("event.users.saga.content.update"),
            })
        );
    }
}

function* email(action: AnyAction) {
    yield call(request, api.email, action);
}

function* roles(action: AnyAction) {
    yield call(request, api.roles, action);
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.CREATE_REQUEST, create);
    yield takeLatest(types.DELETE_REQUEST, deleteUser);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.EMAIL_REQUEST, email);
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.GET_ROLES_REQUEST, roles);
}
