import { LinearProgress } from "@mui/material";

interface IGPTableLoader {
    loading?: boolean;
}

function GPTableLoader({ loading = false }: IGPTableLoader) {
    if (!loading) {
        return null;
    }

    return (
        <LinearProgress
            color="secondary"
            sx={{ position: "absolute", zIndex: 999, width: "100%" }}
        />
    );
}

export default GPTableLoader;
