import {
    Switch,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import PropTypes from "prop-types";
import { SwitchProps } from "@mui/material";
import React, { useContext } from "react";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";

interface IGPSwitch extends Omit<SwitchProps, "onChange"> {
    errorText?: string;
    formControlProps?: object;
    formControlLabelProps?: object;
    helperText?: string;
    id: string;
    label?: string;
    onChange?: Function;
}

function GPSwitch(props: IGPSwitch) {
    const {
        errorText = "",
        formControlProps = {},
        formControlLabelProps = {},
        helperText = "",
        id,
        label = "",
        onChange,
        value,
        ...restProps
    } = props;

    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!!onChange) {
            onChange({ id, value: event.target.checked }, context, event);
        } else {
            context?.onChange({ id, value: event.target.checked });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <FormControl {...formControlProps} error={isError}>
            <FormControlLabel
                {...formControlLabelProps}
                control={
                    <Switch
                        {...restProps}
                        checked={getValue(id, context, value)}
                        onChange={onChangeValue}
                    />
                }
                label={label}
            />
            {inputHelperText.length > 0 && (
                <FormHelperText>{inputHelperText}</FormHelperText>
            )}
        </FormControl>
    );
}

GPSwitch.propTypes = {
    errorText: PropTypes.string,
    formControlProps: PropTypes.object,
    formControlLabelProps: PropTypes.object,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
};

export default GPSwitch;
