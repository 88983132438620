import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React from "react";
import { Icon, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface IPageHeader extends Omit<BoxProps, "title"> {
    actions?: React.ReactNode;
    backBtnUrl?: string;
    breadcrumps?: React.ReactNode;
    title?: string | React.ReactElement;
    py?: { xs: number; sm: number };
    px?: { xs: number; sm: number };
}

function PageHeader({
    actions,
    backBtnUrl,
    breadcrumps,
    title,
    px = { xs: 1.5, sm: 3 },
    pt = { xs: 1, sm: 2 },
    ...restProps
}: IPageHeader) {
    return (
        <Box pt={pt} px={px} {...restProps}>
            {breadcrumps}

            <Stack alignItems="center" direction="row">
                <Box
                    sx={{
                        minWidth: 0,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {!!backBtnUrl && (
                        <IconButton
                            color="inherit"
                            component={Link}
                            to={backBtnUrl}
                            sx={{ mr: 1 }}
                        >
                            <Icon color="secondary">arrow_back</Icon>
                        </IconButton>
                    )}
                    {!!title && (
                        <Typography
                            variant="h5"
                            sx={{
                                // alignItems: "center",
                                // display: "flex",
                                fontWeight: 500,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {title}
                        </Typography>
                    )}
                </Box>
                <Box display="flex" flex={1} justifyContent="flex-end">
                    {actions}
                </Box>
            </Stack>
        </Box>
    );
}

export default PageHeader;
