import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlDoubleId from "types/RequestUrlDoubleId";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/payments/providers/${url.id}`, config),
    fetch: (config: AxiosRequestConfig) =>
        api.get(`/payments/providers`, config),
    fetchDefault: (config: AxiosRequestConfig) =>
        api.get(`/payments/providers/defaults`, config),
    fetchAll: (config: AxiosRequestConfig) =>
        api.get(`/payments/providers/all`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/payments/providers/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`/payments/providers/${url.id}`, data, config),
    storeGenerateKey: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`/payments/providers/${url.id}/generate-key`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlDoubleId) =>
        api.put(`/payments/providers/${url.prevId}/${url.id}`, data, config),
    updateGenerateKey: (data: object, config: AxiosRequestConfig, url: RequestUrlDoubleId) =>
        api.put(`/payments/providers/${url.prevId}/${url.id}/generate-key`, data, config),
};

export default requests;
