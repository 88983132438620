import { UpsaleModel } from "./interfaces";
import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const upsalesCreate = (data: UpsaleModel, onResponse?: Function) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "upsalesCreate",
        { data },
        onResponse
    );

export const upsalesDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "upsalesDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const upsalesFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "upsalesFetch",
        {
            config,
        }
    );

    export const upsalesFetchAll = (config?: AxiosRequestConfig) =>
        request(
            types.FETCH_ALL_REQUEST,
            types.FETCH_ALL_FAILED,
            types.FETCH_ALL_SUCCESS,
            "upsalesFetchAll",
            {
                config,
            }
        );

export const upsalesGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "upsalesGet",
        {
            config,
            url: {
                id,
            },
        }
    );

export const upsalesTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const upsalesUpdate = (id: number, data: any, onResponse?: Function) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "upsalesUpdate",
        { data, url: { id } },
        onResponse
    );
};
