import { registerModals } from "modals";
import UpsalesTable from "./reducers/UpsalesTableReducer";
import UpsaleSaga from "./saga";
import UpsalesSelectModal from "./modals/UpsalesSelectModal";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, configType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "upsale";
export const url = `/upsales`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];


    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;
        registerModals({ UpsalesSelectModal });
        store.reducerManager?.add("UpsaleTable", UpsalesTable);
        store.injectSaga?.("UpsalesSaga", UpsaleSaga);
        routing.push({
            path: url,
            element: <AsyncComponent path="Upsales/pages/UpsalesIndexPage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: <AsyncComponent path="Upsales/pages/UpsalesCreatePage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${url}/:id/edit`,
            element: <AsyncComponent path="Upsales/pages/UpsalesEditPage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
