import { Alert, AlertProps } from "@mui/material";
import trans from "helpers/trans";
interface IGPRequestMessage extends AlertProps {
    message?: string;
    status: number;
}

function GPRequestMessage(props: IGPRequestMessage) {
    const { status, ...restProps } = props;
    let message = props.message;

    if (!message || message.length === 0) {
        if (status === 404) {
            message = trans("ui.requestMessage.message");
        }
    }

    if (!message || message.length === 0) {
        return null;
    }

    const severity = status >= 400 ? "error" : "success";

    return (
        <Alert
            severity={severity}
            {...restProps}
            sx={{ ...restProps.sx, whiteSpace: "pre-line" }}
        >
            {message.replace(/\\n/g, "\n")}
        </Alert>
    );
}

export default GPRequestMessage;
