import { DialogActions, DialogActionsProps } from "@mui/material";

interface IGPModalActions extends DialogActionsProps {}

function GPModalActions(props: IGPModalActions) {
    const { children, ...restProps } = props;

    return (
        <DialogActions
            sx={{
                px: 2,
                pb: 2,
                ...restProps.sx,
            }}
            {...restProps}
        >
            {children}
        </DialogActions>
    );
}

export default GPModalActions;
