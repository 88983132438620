import useRequest from "api/useRequest";
import { bookingsSendEmail } from "../actions";
import { Box, InputAdornment } from "@mui/material";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    NumberField,
    RequestMessage,
    Settings,
    SettingsItem,
    TextField,
} from "ui";

interface IBookingsSendEmailModal {
    customerEmail: string;
    id: string;
    reload: Function;
}

const BookingsSendEmailModal: FC<IBookingsSendEmailModal> = ({
    customerEmail,
    id,
    reload,
}) => {
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);

    const { request, errors, isLoading, message, status } = useRequest();
    const timeReservation = useSelector(
        (state: any) =>
            state.app.settings.location_settings[
                "general-ticket_reservation_time"
            ]
    );
    const handleCloseModal = () => {
        dispatch(modalHide("BookingsSendEmailModal"));
    };

    const handleSave = () => {
        if (formRef.current) {
            request(
                bookingsSendEmail(
                    id,
                    formRef.current.getData(),
                    (response: AxiosResponse) => {
                        if (response.status === 204) {
                            dispatch(modalHide("BookingsSendEmailModal"));
                            reload();
                        }
                    }
                )
            );
        }
    };

    return (
        <Modal open fullWidth maxWidth="xs">
            <ModalTitle onClose={handleCloseModal}>
                {trans("event.bookings.modal.bookingsCheckoutModal.title")}
            </ModalTitle>
            <ModalContent>
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{
                        mb: 1,
                    }}
                />
                <Form
                    ref={formRef}
                    data={{}}
                    fields={{
                        ticket_reservation_time: {
                            default: timeReservation,
                        },
                        customer_email: { default: customerEmail },
                    }}
                    errors={errors}
                    onSubmit={() => {}}
                    unsaved={false}
                >
                    <Settings>
                        <SettingsItem
                            label={trans(
                                "event.bookings.modal.bookingsCheckoutModal.label.customerEmail"
                            )}
                        >
                            <TextField
                                id="customer_email"
                                fullWidth
                                autoFocus
                            />
                        </SettingsItem>
                        <SettingsItem
                            label={trans(
                                "event.bookings.modal.bookingsCheckoutModal.label.expiredTime"
                            )}
                        >
                            <NumberField
                                fullWidth
                                id="ticket_reservation_time"
                                InputProps={{
                                    inputProps: { min: 0 },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            min
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </SettingsItem>
                    </Settings>
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <LoadingButton
                        sx={{ flex: 1 }}
                        onClick={handleSave}
                        loading={isLoading}
                    >
                        {trans("global.button.send")}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};
export default BookingsSendEmailModal;
