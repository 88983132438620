import { FC, useMemo } from "react";
import { languagesDefault } from "../actions";
import useRequest from "api/useRequest";
import { Loader, RequestMessage, Select } from "ui";
import { Box, MenuItem } from "@mui/material";
import usePermission from "hooks/usePermission";
import { LanguageModel } from "../interfaces";
import { countriesList } from "CountriesISO";
import { IGPSelect } from "ui/Form/Select";

interface ILanguagesSelect extends IGPSelect {
    id: string;
    onChangeLang?: Function;
}
const LanguagesSelect: FC<ILanguagesSelect> = ({
    id,
    onChangeLang,
    ...restProps
}) => {
    const permission = usePermission("Languages", "read");
    const { data, isError, isLoading, message, status } = useRequest(
        languagesDefault()
    );

    const preparedData = useMemo(() => {
        if (!data) return [];
        else return data;
    }, [data]);

    const handleChangeLang = (
        e: { id: string; value: string },
        context: any
    ) => {
        const selectedLang = preparedData.find(
            (lang: any) => lang.key === e.value
        );
        if (onChangeLang) {
            onChangeLang(e, context, selectedLang);
        } else {
            context.onChange({ id, value: e.value });
        }
    };

    if (isLoading) return <Loader />;
    if (isError || !permission)
        return <RequestMessage status={status} message={message} />;

    return (
        <Select
            id={id}
            fullWidth
            onChange={handleChangeLang}
            {...restProps}
        >
            {preparedData.map((item: LanguageModel) => {
                const currentCountry = countriesList.find(
                    (country) => country.country === item.flag
                );
                return (
                    <MenuItem key={`lang-${item.key}`} value={item.key}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: 1,
                            }}
                        >
                            {currentCountry && (
                                <div
                                    className={`fflag ff-md fflag-${currentCountry.flag.toUpperCase()}`}
                                />
                            )}
                            {item.name}
                        </Box>
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default LanguagesSelect;
