import api from "api/apiReducer";
import app from "app/App/reducer";
import me from "app/Me/reducer";
import web from "app/WebEvent/reducer";

const uuid = window.location.pathname.match(/\/tickets\/([A-Za-z0-9-]+)/)?.[1];

const rootReducer = {
    api,
    app,
    me,
    ...(uuid && { web }),
};

export default rootReducer;
