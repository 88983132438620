import { DialogContent, DialogContentProps } from "@mui/material";

interface IGPModalContent extends DialogContentProps {}

function GPModalContent(props: IGPModalContent) {
    const { children, ...restProps } = props;

    return (
        <DialogContent
            sx={{
                px: 2,
                py: 1,
                ...restProps.sx,
            }}
            {...restProps}
        >
            {children}
        </DialogContent>
    );
}

export default GPModalContent;
