import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "ui/theme";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppInit from "app/App/components/AppInit";
import "helpers/formats";
import reportWebVitals from "./reportWebVitals";
import store from "./rootStore";

import "ui/CountrySelect/freakflags.css";

import "moment/locale/nb";
import "moment/locale/en-gb";

import 'dayjs/locale/nb'
import 'dayjs/locale/en'

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const pathname = window.location.pathname.split("/");

root.render(
    // <React.StrictMode>
    <BrowserRouter {...(pathname[1] !== "tickets" && {basename: pathname[1]})}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppInit />
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
