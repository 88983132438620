import { FC, useContext, useEffect, useState } from "react";
import { IFormChangeResponse } from "./Form";
import FormContext from "./FormContext";
import { getValue } from "./helpers";
import TextField, { IGPTextField } from "./TextField";
import { Icon, IconButton } from "@mui/material";

export interface IQuantityField extends IGPTextField {
    maxPrecision?: number;
    precision?: number;
    value?: number | string;
    disabled?: boolean;
    float?: boolean;
    maxValue?: number;
    minValue?: number;
}

const calculateFloatString = (
    val: string,
    precision: number,
    maxPrecision: number
) => {
    let floatNumber = "";
    if (val !== "") {
        floatNumber = (+val + 0.0).toFixed(precision);
        if (maxPrecision === 0) {
            floatNumber = (+val + 0.0).toFixed(0);
        } else if (precision === 0) {
            floatNumber = val;
        }
    }

    return floatNumber;
};

const QuantityField: FC<IQuantityField> = ({
    id,
    onChange,
    value,
    onBlur,
    precision = 0,
    maxPrecision = 3,
    disabled = false,
    float = false,
    maxValue,
    minValue = 0,
    ...restProps
}) => {
    const context = useContext(FormContext);
    const inputValue = getValue(id, context, value);

    const [val, setVal] = useState(
        inputValue || inputValue === 0
            ? calculateFloatString(inputValue, precision, maxPrecision)
            : ""
    );

    useEffect(() => {
        const prepareInputVal =
            inputValue || inputValue === 0
                ? calculateFloatString(inputValue, precision, maxPrecision)
                : "";
        if (+prepareInputVal !== +val) {
            handleChange({ id, value: prepareInputVal.toString() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, precision, maxPrecision, val]);

    const handleChange = ({ value }: IFormChangeResponse) => {
        if (!float && Number.isInteger(+value)) {
            const regex = new RegExp(`^\\d{0,16}?$`);
            if (regex.test(value) || value.length === 0) {
                if (!!onChange) {
                    onChange({
                        id,
                        value: +value,
                    });
                } else {
                    context?.onChange({
                        id,
                        value: +value,
                    });
                }
                setVal(value);
            }
        } else if (float) {
            const targetValue = value.replace(",", ".");
            let regex = new RegExp(`^\\d{0,16}([.]\\d{0,${precision}})?$`);
            if (maxPrecision === 0) {
                regex = new RegExp(`^\\d{0,16}?$`);
            } else if (precision === 0) {
                regex = new RegExp(`^\\d{0,16}([.]\\d{0,${maxPrecision}})?$`);
            }

            if (targetValue.length === 0 || regex.test(targetValue)) {
                const intValue = parseFloat(targetValue);

                if (!!onChange) {
                    onChange({
                        id,
                        value: intValue,
                    });
                } else {
                    context?.onChange({
                        id,
                        value: intValue,
                    });
                }

                setVal(targetValue);
            }
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!!onBlur) {
            onBlur(e);
        }
        if (maxValue && +val > maxValue) {
            handleChange({ id, value: maxValue.toString() });
            setVal(
                calculateFloatString(
                    maxValue.toString(),
                    precision,
                    maxPrecision
                )
            );
        } else {
            setVal(calculateFloatString(val, precision, maxPrecision));
        }
    };

    const handleRemove = () => {
        const newValue = (+val - 1).toFixed(precision || maxPrecision);
        const formattedValue = parseFloat(newValue).toString();
        handleChange({ id, value: formattedValue });
    };
    const handleAdd = () => {
        const newValue = (+val + 1).toFixed(precision || maxPrecision);
        const formattedValue = parseFloat(newValue).toString();
        handleChange({ id, value: formattedValue });
    };

    return (
        <TextField
            {...restProps}
            disabled={disabled}
            id={id}
            onChange={handleChange}
            value={val}
            onBlur={handleBlur}
            InputLabelProps={{
                shrink: true,
            }}
            sx={{
                ".MuiFormHelperText-root.Mui-error": {
                    ml: 1,
                },
                ".MuiInputBase-root": {
                    justifyContent: "space-between",
                    padding: 0,
                    width: 150,
                },
                "input.MuiInputBase-input": {
                    textAlign: "center",
                },
                ...restProps.sx,
            }}
            InputProps={{
                inputMode: float ? "numeric" : "decimal",
                startAdornment: (
                    <IconButton
                        onClick={handleRemove}
                        disabled={
                            disabled || +val <= minValue || +val - 1 < minValue
                        }
                    >
                        <Icon
                            sx={{
                                fontSize: 20,
                            }}
                        >
                            remove
                        </Icon>
                    </IconButton>
                ),
                endAdornment: (
                    <IconButton
                        onClick={handleAdd}
                        disabled={
                            disabled ||
                            (!!maxValue &&
                                (+val >= maxValue || +val + 1 > maxValue))
                        }
                    >
                        <Icon
                            sx={{
                                fontSize: 20,
                            }}
                        >
                            add
                        </Icon>
                    </IconButton>
                ),
            }}
        />
    );
};

export default QuantityField;
