import { Box } from "@mui/material";
import { modalHide } from "app/App/actions";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalContent, ModalTitle } from "ui";

interface IUpsaleImagesModal {
    image: string;
    id: number;
}

const UpsaleImageModal: FC<IUpsaleImagesModal> = ({ image, id }) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(modalHide("UpsaleImageModal"));
    };
    return (
        <Modal maxWidth={"sm"} fullWidth open fullScreen={false} scroll="paper">
            <ModalTitle onClose={handleClose}></ModalTitle>

            <ModalContent
                sx={{
                    pt: 0,
                    px: 0,
                    display: "flex",
                    flexDirection: "column",
                    py: 0,
                    gap: 2,
                }}
            >
                <Box
                    component="img"
                    src={image}
                    alt={`upsale-${id}`}
                    sx={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                    }}
                />
            </ModalContent>
        </Modal>
    );
};

export default UpsaleImageModal;
