import React, { forwardRef, useContext, useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";
import {
    Box,
    FormControl,
    FormHelperText,
    Icon,
    IconButton,
    InputLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
    convertFromRaw,
    convertToRaw,
    Editor,
    EditorState,
    RichUtils,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import PropTypes from "prop-types";
import { countriesList } from "CountriesISO";
import { LanguageModel } from "app/Languages/interfaces";

export interface IGPTranslationsWysywig
    extends Omit<TextFieldProps, "onChange"> {
    errorText?: string;
    formControlProps?: object;
    helperText?: string;
    labelProps?: object;
    stripPastedStyles?: boolean;
    label?: string;
    value?: any;
    id: string;
    onChange?: Function;
    prefixLangId: string;
}
const initEditor = (value: string) => {
    if (!value) return EditorState.createEmpty();
    try {
        return EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
    } catch (e) {
        return EditorState.createEmpty();
    }
};

const GPTranslationsWysywig = forwardRef<any, IGPTranslationsWysywig>(
    (props, ref) => {
        const {
            errorText = "",
            formControlProps = {},
            fullWidth,
            helperText = "",
            id,
            label,
            labelProps = {},
            margin,
            onChange,
            value,
            variant,
            prefixLangId,
            stripPastedStyles = true,
        } = props;
        const currentLang = useSelector((state: any) => state.app.lang);
        const languages = useSelector(
            (state: any) => state.app.settings.languages
        );
        const currentLangObj = languages?.find(
            (lang: LanguageModel) => lang.key === currentLang
        );
        const currentCountry = countriesList.find(
            (country) => country.country === currentLangObj?.flag
        );
        const draftId = `${id}_${currentLang}`;
        const langId = `${prefixLangId}_${currentLang}`;

        const context = useContext(FormContext);

        const [editor, setEditor] = useState(
            initEditor(getValue(draftId, context, value))
        );

        useEffect(() => {
            setEditor(initEditor(getValue(draftId, context, value)));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentLang]);

        const onChangeValue = (editor: any) => {
            const inputValue = draftToHtml(
                convertToRaw(editor.getCurrentContent())
            );
            const draftJSON = JSON.stringify(
                convertToRaw(editor.getCurrentContent())
            );
            const parsedDraft = JSON.parse(draftJSON);
            const isText =
                parsedDraft?.blocks.filter((block: any) => block.text.trim())
                    ?.length > 0;
            if (!!onChange) {
                onChange(
                    {
                        id: draftId,
                        value: inputValue,
                    },
                    context,
                    draftJSON
                );
            } else {
                context?.onChange([
                    {
                        id: draftId,
                        value: draftJSON,
                    },
                    {
                        id: langId,
                        value: isText ? inputValue : "",
                    },
                ]);
            }

            setEditor(editor);
        };

        const onClickBold = () => {
            onChangeValue(RichUtils.toggleInlineStyle(editor, "BOLD"));
        };
        const onClickItalic = () => {
            onChangeValue(RichUtils.toggleInlineStyle(editor, "ITALIC"));
        };
        const onClickStrikeThrough = () => {
            onChangeValue(RichUtils.toggleInlineStyle(editor, "STRIKETHROUGH"));
        };
        const onClickUnderline = () => {
            onChangeValue(RichUtils.toggleInlineStyle(editor, "UNDERLINE"));
        };

        const handleKeyCommand = (command: string) => {
            const newState = RichUtils.handleKeyCommand(editor, command);

            if (newState) {
                onChangeValue(newState);
                return "handled";
            }

            return "not-handled";
        };

        const { inputHelperText, isError } = getHelperText(
            langId,
            context,
            errorText,
            helperText
        );

        const activeToolbarButtons = editor.getCurrentInlineStyle();
        return (
            <FormControl
                {...formControlProps}
                error={isError}
                fullWidth={fullWidth}
                margin={margin}
                variant={variant}
            >
                {!!label && (
                    <InputLabel {...labelProps} id={`${draftId}-label`} shrink>
                        {label}
                    </InputLabel>
                )}

                <Box
                    sx={{
                        border: (theme) =>
                            `1px solid ${theme.palette.grey[400]}`,
                        borderRadius: 1,
                        "& .public-DraftEditor-content": {
                            minHeight: 48,
                        },
                        "& .public-DraftStyleDefault-block": {
                            margin: "0!important",
                        },
                    }}
                >
                    <Box
                        sx={{
                            alignItems: "center",
                            borderBottom: (theme) =>
                                `1px solid ${theme.palette.grey[300]}`,
                            color: "text.secondary",
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box>
                            <IconButton
                                onClick={onClickBold}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                <Icon
                                    sx={[
                                        activeToolbarButtons.has("BOLD") && {
                                            color: "#000",
                                        },
                                    ]}
                                >
                                    format_bold
                                </Icon>
                            </IconButton>
                            <IconButton
                                onClick={onClickItalic}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                <Icon
                                    sx={[
                                        activeToolbarButtons.has("ITALIC") && {
                                            color: "#000",
                                        },
                                    ]}
                                >
                                    format_italic
                                </Icon>
                            </IconButton>
                            <IconButton
                                onClick={onClickUnderline}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                <Icon
                                    sx={[
                                        activeToolbarButtons.has(
                                            "UNDERLINE"
                                        ) && {
                                            color: "#000",
                                        },
                                    ]}
                                >
                                    format_underline
                                </Icon>
                            </IconButton>
                            <IconButton
                                onClick={onClickStrikeThrough}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                <Icon
                                    sx={[
                                        activeToolbarButtons.has(
                                            "STRIKETHROUGH"
                                        ) && {
                                            color: "#000",
                                        },
                                    ]}
                                >
                                    strikethrough_s
                                </Icon>
                            </IconButton>
                        </Box>
                        {currentCountry && (
                            <Box
                                sx={{
                                    display: "flex",
                                    padding: 1,
                                }}
                            >
                                <div
                                    className={`fflag ff-sm fflag-${currentCountry.flag.toUpperCase()}`}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Editor
                            editorState={editor}
                            handleKeyCommand={handleKeyCommand}
                            onChange={onChangeValue}
                            stripPastedStyles={stripPastedStyles}
                        />
                    </Box>
                </Box>

                {inputHelperText.length > 0 && (
                    <FormHelperText>{inputHelperText}</FormHelperText>
                )}
            </FormControl>
        );
    }
);

GPTranslationsWysywig.propTypes = {
    errorText: PropTypes.string,
    formControlProps: PropTypes.object,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    labelProps: PropTypes.object,
    onChange: PropTypes.func,
    stripPastedStyles: PropTypes.bool,
};

export default GPTranslationsWysywig;
