import {
    APP_INIT,
    APP_LOADED,
    APP_SETTINGS_SUCCESS,
    MODAL_HIDE,
    MODAL_SHOW,
    SNACKBAR_HIDE,
    SNACKBAR_SHOW,
    SIDE_NAV_TYPE,
    LOCALE_REQUEST,
    LOCALE_SUCCESS,
    CHANGE_LANG,
    SHOW_COOKIE_CONSENT,
    HIDE_COOKIE_CONSENT,
} from "types";

import { AnyAction } from "redux";

import { rs } from "helpers/reducers";
import { LanguageModel } from "app/Languages/interfaces";

interface IState {
    loaded: boolean;
    locationId: number | null;
    locations: any;
    modals: Array<{
        id: string;
    }>;
    request: any;
    settings: any;
    snackbar: any;
    sideNavType: string;
    app: string;
    lang: string;
    showCookie: boolean;
}
function general(
    state: IState = {
        loaded: false,
        locationId: null,
        locations: {},
        modals: [],
        request: {},
        settings: {},
        snackbar: null,
        sideNavType: "main",
        app: "App",
        lang: "",
        showCookie: false,
    },
    action = {} as AnyAction
) {
    switch (action.type) {
        case APP_INIT:
            return rs.init(state);
        case SHOW_COOKIE_CONSENT:
            return {
                ...state,
                showCookie: action.showCookie,
            };
        case HIDE_COOKIE_CONSENT:
            return {
                ...state,
                showCookie: action.showCookie,
            };
        case APP_LOADED:
            return {
                ...state,
                app: action.app,
                loaded: true,
                locationId: action.locationId,
                locations: action.locations,
            };

        case APP_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.response.data.data,
                lang: action.response.data.data.languages.find(
                    (lang: LanguageModel) => lang.is_default
                )?.key,
            };

        case MODAL_HIDE:
            return {
                ...state,
                modals: state.modals.filter((modal) => modal.id !== action.id),
            };

        case MODAL_SHOW:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    {
                        id: action.id,
                        props: action.props,
                    },
                ],
            };

        case SNACKBAR_HIDE:
            return {
                ...state,
                snackbar: null,
            };
        case SNACKBAR_SHOW:
            return {
                ...state,
                snackbar: action.data,
            };
        case SIDE_NAV_TYPE:
            return {
                ...state,
                sideNavType: action.data,
            };
        case LOCALE_REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case LOCALE_SUCCESS:
            return {
                ...state,
                loaded: true,
            };
        case CHANGE_LANG:
            return {
                ...state,
                lang: action.data,
            };
        default:
            return state;
    }
}

export default general;
