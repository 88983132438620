import types from "./types";

import { request } from "api/actions";

export const ticketReader = (id: string, data?: any, onResponse?: Function) =>
    request(
        types.TICKET_READER_REQUEST,
        types.TICKET_READER_FAILED,
        types.TICKET_READER_SUCCESS,
        "ticketReader",
        {
            data,
            url: {
                id,
            },
        },
        onResponse
    );
