import { LanguageModel, LanguageModelUpdate } from "./interfaces";
import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const languagesCreate = (data: LanguageModel, onResponse?: Function) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "languagesCreate",
        { data },
        onResponse
    );

export const languagesDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "languagesDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const languagesFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "languagesFetch",
        {
            config,
        }
    );

    export const languagesDefault = (config?: AxiosRequestConfig) =>
        request(
            types.FETCH_DEFAULT_REQUEST,
            types.FETCH_DEFAULT_FAILED,
            types.FETCH_DEFAULT_SUCCESS,
            "languagesDefault",
            {
                config,
            }
        );

export const languagesFetchAll = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_ALL_REQUEST,
        types.FETCH_ALL_FAILED,
        types.FETCH_ALL_SUCCESS,
        "languagesFetchAll",
        {
            config,
        }
    );

export const languagesGet = (id: number) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "languagesGet",
        {
            url: {
                id,
            },
        }
    );

export const languagesTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const languagesUpdate = (
    id: number,
    data: LanguageModel,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "languagesUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const languagesPatch = (
    id: number,
    data: LanguageModelUpdate,
    onResponse?: Function
) => {
    return request(
        types.PATCH_REQUEST,
        types.PATCH_FAILED,
        types.PATCH_SUCCESS,
        "languagesPatch",
        { data, url: { id } },
        onResponse
    );
};