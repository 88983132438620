import {
    IChooseLocationModal,
    ILocationGroup,
    ILocationGroups,
} from "../interfaces";
import {
    Avatar,
    Icon,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Box,
} from "@mui/material";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Modal, ModalActions, ModalContent, ModalTitle } from "ui";

const LOCATION_TYPE_ICON = {
    STORE: "storefront",
    REGULAR: "home",
    SUPPLIER: "local_shipping",
    SATELLITE: "satellite_alt",
};

const ChooseLocationModal: FC<IChooseLocationModal> = () => {
    const dispatch = useDispatch();
    const locationGroups = useSelector(
        (state: any) => state.me.location_groups
    );

    const handleClose = () => {
        dispatch(modalHide("ChooseLocationModal"));
    };

    const handleLocation = (item: ILocationGroup) => (event: any) => {
        window.location.href = "/" + item.uid;
        dispatch(modalHide("ChooseLocationModal"));
    };

    const renderLocations = (locations: ILocationGroup[]) => {
        return locations.map((item: ILocationGroup) => {
            return (
                <ListItem
                    button
                    sx={{ width: "100%" }}
                    onClick={handleLocation(item)}
                    key={`choose-location-${item.id}`}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <Icon>{LOCATION_TYPE_ICON["REGULAR"]}</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                </ListItem>
            );
        });
    };

    const renderGroup = (group: ILocationGroups) => {
        if (group.locations.length === 0) return null;

        return (
            <Box key={group.id}>
                <Typography
                    variant="h6"
                    fontWeight={500}
                    sx={{
                        px: 2,
                        py: 1,
                    }}
                >
                    {group.name}
                </Typography>
                <List sx={{ width: "100%" }}>
                    {renderLocations(group.locations)}
                </List>
            </Box>
        );
    };

    return (
        <>
            <Modal
                maxWidth="xs"
                fullWidth
                onClose={handleClose}
                open
                PaperProps={{ sx: { position: "fixed", top: 0 } }}
            >
                <ModalTitle onClose={handleClose} fontWeight={500}>
                    {trans("event.me.modal.chooseLocationModal.title")}
                </ModalTitle>
                <ModalContent
                    sx={{
                        px: 0,
                        py: 1,
                    }}
                >
                    {locationGroups.map((item: any) => renderGroup(item))}
                </ModalContent>
                <ModalActions></ModalActions>
            </Modal>
        </>
    );
};

export default ChooseLocationModal;
