import { languagesCreate } from "../actions";
import { Box } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Settings,
    SettingsItem,
    Switch,
    TextField,
} from "ui";
import LanguagesSelect from "../components/LanguagesSelect";
interface ILanguagesCreateModal {
    onSuccess: Function;
}
const LanguagesCreateModal: FC<ILanguagesCreateModal> = ({ onSuccess }) => {
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);
    const { request, errors, isLoading, message, status } = useRequest();

    const handleCloseModal = () => {
        dispatch(modalHide("LanguagesCreateModal"));
    };
    const handleChangeLang = (
        e: { id: string; value: string },
        context: any,
        selectedLang: any
    ) => {
        context.onChange([
            {
                id: e.id,
                value: e.value,
            },
            {
                id: "name",
                value: selectedLang?.name,
            },
            {
                id: "is_default",
                value: false,
            },
            {
                id: "flag",
                value: selectedLang?.flag,
            },
        ]);
    };
    const handleSubmit = () => {
        if (formRef.current) {
            const data = formRef.current.getData();
            request(
                languagesCreate(data, (response: AxiosResponse) => {
                    if (response.status === 201) {
                        onSuccess();
                    }
                })
            );
        }
    };

    return (
        <Modal open maxWidth="xs" fullWidth onClose={handleCloseModal}>
            <ModalTitle onClose={handleCloseModal}>
                {" "}
                {trans("global.text.create")}
            </ModalTitle>
            <ModalContent>
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{ mb: 1 }}
                />
                <Form
                    ref={formRef}
                    data={{}}
                    errors={errors}
                    fields={{
                        name: { default: "" },
                        key: { default: "" },
                        is_default: { type: "boolean", default: false },
                        flag: { default: "" },
                    }}
                    loading={isLoading}
                    onSubmit={() => {}}
                    preventLeftForm
                    unsaved={false}
                >
                    {(context: any) => {
                        return (
                            <Settings>
                                <SettingsItem
                                    label={trans("event.languages.form.key")}
                                >
                                    <LanguagesSelect
                                        id="key"
                                        onChangeLang={handleChangeLang}
                                        autoFocus
                                    />
                                </SettingsItem>
                                {context.data.name && (
                                    <>
                                        <SettingsItem
                                            label={trans(
                                                "event.languages.form.name"
                                            )}
                                        >
                                            <TextField id="name" fullWidth />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "event.languages.form.isDefault"
                                            )}
                                        >
                                            <Switch id="is_default" />
                                        </SettingsItem>
                                    </>
                                )}
                            </Settings>
                        );
                    }}
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <LoadingButton
                        sx={{ flex: 1 }}
                        onClick={handleSubmit}
                        loading={isLoading}
                    >
                        {trans("global.button.save")}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default LanguagesCreateModal;
