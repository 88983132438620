import { webEventGet } from "../actions";
import { IWebTicket } from "../interfaces";
import WebEventLoader from "./WebEventLoader";

import {
    Box,
    Chip,
    Divider,
    Icon,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import useRequest from "api/useRequest";
import getSubtranslation from "helpers/getSubtranslation";
import trans from "helpers/trans";
import { orderBy } from "lodash";
import moment from "moment";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, ModalContent, ModalTitle, RequestMessage } from "ui";

interface ITicketFirstStep {
    uuid: string;
    onBuy: Function;
    children: React.ReactNode;
    guests: number;
    onCloseModal: Function;
}

const TicketFirstStep: FC<ITicketFirstStep> = ({
    children,
    guests,
    uuid,
    onBuy,
    onCloseModal,
}) => {
    const lang = document.documentElement.lang;
    const primaryColor = useSelector(
        (state: any) =>
            state.web.settings.location_settings["general-primary_color"]
    );
    const smallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("tablet")
    );

    const { data, isLoading, isError, message, status } = useRequest(
        webEventGet(uuid)
    );

    const preparedData = useMemo(() => {
        if (!data?.data) return null;

        return {
            ...data.data,
            description: getSubtranslation(
                data.data.subtranslations.description
            ),
            title: getSubtranslation(data.data.subtranslations.title),
            location: getSubtranslation(data.data.subtranslations.location),
        };
    }, [data]);

    if (isLoading)
        return (
            <Box position="relative" sx={{ height: "100%" }}>
                <WebEventLoader />
            </Box>
        );
    if (isError) {
        return (
            <>
                <ModalTitle
                    onClose={() => onCloseModal()}
                    sx={{
                        pl: 2,
                        pt: 1,
                        pr: 1,
                        pb: { xs: 2, tablet: 0 },
                        ".MuiTypography-root": {
                            color: "#495366",
                            fontFamily: "'Lato', sans-serif",
                            fontSize: 18,
                            fontWeight: 700,
                        },
                    }}
                />
                <ModalContent
                    sx={{
                        pt: 0,
                        px: { xs: 2, tablet: 4 },
                        display: "flex",
                        flexDirection: "column",
                        pb: 0,
                    }}
                >
                    <RequestMessage
                        message={message}
                        status={status}
                        sx={{
                            "&.MuiPaper-root": {
                                width: "100% !important",
                                height: "fit-content",
                                mb: 2,
                            },
                        }}
                    />
                </ModalContent>
            </>
        );
    }
    if (!preparedData) return null;

    return (
        <>
            <ModalTitle
                onClose={() => onCloseModal()}
                sx={{
                    pl: 2,
                    pt: 1,
                    pr: 1,
                    pb: { xs: 1.5, tablet: 0 },
                    ".MuiTypography-root": {
                        color: "#495366",
                        fontFamily: "'Lato', sans-serif",
                        fontSize: 18,
                        fontWeight: 700,
                    },
                }}
                modalActions={
                    smallScreen &&
                    preparedData.age_limit && (
                        <Box
                            sx={{
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "start",
                                alignSelf: "start",
                                ml: "auto",
                            }}
                        >
                            <Box
                                sx={{
                                    background: "#fff",
                                    color: "#000",
                                    borderRadius: "50%",
                                    textAlign: "center",
                                    border: "2px solid red",
                                    height: 36,
                                    width: 36,
                                    margin: "2px",
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 500,
                                    fontSize: 13,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {preparedData.age_limit}+
                            </Box>
                        </Box>
                    )
                }
            >
                {smallScreen && preparedData.title}
            </ModalTitle>
            <ModalContent
                sx={{
                    pt: 0,
                    px: { xs: 2, tablet: 4 },
                    display: "flex",
                    flexDirection: "column",
                    pb: 0,
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontFamily: "'Lato', sans-serif",
                            fontSize: { xs: 16, tablet: 20 },
                            color: { xs: "#495366", tablet: "#000" },
                            fontWeight: 700,
                            textAlign: "center",
                            mb: { xs: 1.5, tablet: 2.5 },
                        }}
                    >
                        {trans(
                            `event.webEvent.modal.ticketModal.title.chooseDate`
                        )}
                    </Typography>
                    <Stack
                        divider={
                            <Divider
                                orientation="horizontal"
                                flexItem
                                sx={{ my: { xs: 2, tablet: 2.5 } }}
                            />
                        }
                    >
                        <Stack
                            sx={{
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: {
                                        xs: "'Noto Sans', sans-serif",
                                        tablet: "'Lato', sans-serif",
                                    },
                                    fontSize: { xs: 13, tablet: 18 },
                                    fontWeight: { xs: 400, tablet: 700 },
                                    color: { xs: "#495366", tablet: "#02263A" },
                                }}
                            >
                                {preparedData.title}
                            </Typography>
                            {!smallScreen && preparedData.age_limit && (
                                <Box
                                    sx={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        alignSelf: "start",
                                        ml: "auto",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background: "#fff",
                                            color: "#000",
                                            borderRadius: "50%",
                                            textAlign: "center",
                                            border: "2px solid red",
                                            height: 36,
                                            width: 36,
                                            margin: "2px",
                                            fontFamily:
                                                "'Noto Sans', sans-serif",
                                            fontWeight: 500,
                                            fontSize: 13,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {preparedData.age_limit}+
                                    </Box>
                                </Box>
                            )}
                        </Stack>
                        {children}
                    </Stack>
                </Box>
                <List
                    sx={{
                        flex: 1,
                        display: "flex",
                        minHeight: "90px",
                        overflowY: "auto",
                        overflowX: "auto",
                        minWidth: 300,
                        mt: { xs: 2, tablet: 3.5 },
                        p: 0,
                        paddingRight: 1,
                        listStyleType: "disc",
                        listStylePosition: "inside",
                        fontFamily: "'Noto Sans', sans-serif",
                        fontSize: 18,
                        fontWeight: 500,
                        color: "#495366",
                        gap: { xs: 2, tablet: 2.5 },
                        flexDirection: "column",
                    }}
                >
                    {orderBy(
                        [preparedData, ...preparedData.group_events],
                        "start_datetime"
                    ).map((item: any, idx: number) => {
                        const format = "YYYY-MM-DD HH:mm:ss";
                        const utcTime = moment.utc(item.start_datetime, format);
                        const title =
                            item.subtranslations.title[`lang_${lang}`];
                        const notAvailable = item.event_tickets.every(
                            (item: IWebTicket) =>
                                item.current_available < guests ||
                                item.current_available === 0
                        );
                        const available = item.event_tickets.every(
                            (item: IWebTicket) =>
                                item.current_available >= guests &&
                                item.current_available !== 0
                        );
                        const partlyAvailable = item.event_tickets.some(
                            (item: IWebTicket) =>
                                item.current_available < guests ||
                                item.current_available === 0
                        );
                        return (
                            <Box key={`event-${idx}`}>
                                <ListItem
                                    key={`date-${item.uuid}`}
                                    sx={{
                                        p: 0,
                                        alignItems: "start",
                                        justifyContent: "space-between",
                                        my: 0,
                                        flexWrap: "wrap",
                                        gap: 1,
                                        rowGap: 1,
                                    }}
                                >
                                    <ListItemText
                                        primary={moment(utcTime)
                                            .local()
                                            .locale(
                                                lang === "en" ? "en-gb" : lang
                                            )
                                            .format("dddd, DD.MM.YYYY")}
                                        secondary={title}
                                        sx={{
                                            my: 0,
                                            flex: "inherit",
                                            flexBasis: {
                                                xs: "calc(100% / 2 - 4px)",
                                                tablet: "auto",
                                            },
                                            ".MuiTypography-root": {
                                                fontFamily:
                                                    "'Noto Sans', sans-serif",
                                                fontSize: {
                                                    xs: 14,
                                                    tablet: 16,
                                                },
                                                fontWeight: 500,
                                                color: "#495366",
                                                width: 210,
                                            },
                                            ".MuiTypography-body2": {
                                                fontFamily:
                                                    "'Noto Sans', sans-serif",
                                                fontSize: 13,
                                                fontWeight: 400,
                                                color: "#90959E",
                                            },
                                        }}
                                    />
                                    <ListItemText
                                        primary={moment(utcTime)
                                            .local()
                                            .format("HH:mm")}
                                        sx={{
                                            my: 0,
                                            width: "fit-content",
                                            flex: "none",
                                            display: "flex",
                                            flexBasis: {
                                                xs: "calc(100% / 2 - 4px)",
                                                tablet: "auto",
                                            },
                                            justifyContent: "end",
                                            ".MuiTypography-root": {
                                                fontFamily:
                                                    "'Noto Sans', sans-serif",
                                                fontSize: {
                                                    xs: 14,
                                                    tablet: 16,
                                                },
                                                fontWeight: {
                                                    xs: 400,
                                                    tablet: 500,
                                                },
                                                color: "#495366",
                                            },
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flexBasis: {
                                                xs: "calc(100% / 2 - 4px)",
                                                tablet: "auto",
                                            },
                                            minWidth: "110px",
                                        }}
                                    >
                                        <Chip
                                            sx={{
                                                height: 28,
                                                minWidth: "fit-content",
                                                width: "fit-content",
                                                fontFamily: "'Noto Sans'",
                                                fontWeight: 500,
                                                fontSize: 12,
                                                backgroundColor: notAvailable
                                                    ? "#FFC3BB66"
                                                    : partlyAvailable
                                                    ? "#FFEBCF"
                                                    : "#D9F0E4",
                                                color: notAvailable
                                                    ? "#650000"
                                                    : partlyAvailable
                                                    ? "#662C1A"
                                                    : "#054808",
                                            }}
                                            icon={
                                                <Icon
                                                    sx={{
                                                        ml: "8px !important",
                                                        fontSize: 20,
                                                        color: notAvailable
                                                            ? `#970000 !important`
                                                            : partlyAvailable
                                                            ? `#DC6800 !important`
                                                            : `#007D39 !important`,
                                                    }}
                                                >
                                                    {notAvailable
                                                        ? "highlight_off"
                                                        : "check_circle_outline"}
                                                </Icon>
                                            }
                                            label={
                                                notAvailable
                                                    ? trans(
                                                          "event.webEvent.modal.ticketModal.soldOut"
                                                      )
                                                    : available
                                                    ? trans(
                                                          "event.webEvent.modal.ticketModal.availableText"
                                                      )
                                                    : trans(
                                                          "event.webEvent.modal.ticketModal.partlyAvailable"
                                                      )
                                            }
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            flexBasis: {
                                                xs: "calc(100% / 2 - 4px)",
                                                tablet: "auto",
                                            },
                                            display: "flex",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <Button
                                            onClick={() => onBuy(item.uuid, item.id)}
                                            disabled={
                                                notAvailable || guests === 0
                                            }
                                            variant="contained"
                                            sx={{
                                                ":hover": {
                                                    background: primaryColor,
                                                    boxShadow: "none",
                                                },

                                                ml: "0 auto",
                                                background: primaryColor,
                                                fontFamily:
                                                    "'Noto Sans', sans-serif",
                                                fontWeight: 500,
                                                fontSize: {
                                                    xs: 14,
                                                    tablet: 16,
                                                },
                                                borderRadius: 1,
                                                boxShadow: "none",
                                                padding: "6px 16px",
                                                height: 36,
                                                display: "flex",
                                                width: 97,
                                                color: (theme) =>
                                                    theme.palette.getContrastText(
                                                        primaryColor
                                                    ),
                                            }}
                                        >
                                            {trans(
                                                "event.webEvent.modal.ticketModal.buy"
                                            )}
                                        </Button>
                                    </Box>
                                </ListItem>
                                {smallScreen && <Divider sx={{ mt: 2 }} />}
                            </Box>
                        );
                    })}
                </List>
            </ModalContent>
            {!smallScreen && <Divider sx={{ mt: 4 }} />}
        </>
    );
};
export default TicketFirstStep;
