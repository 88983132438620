export const FETCH_SUCCESS = "USERS/FETCH_SUCCESS";
export const TABLE_UPDATE = "USERS/TABLE_UPDATE";

const types = {
    FETCH_SUCCESS,
    FETCH_FAILED: "USERS/FETCH_FAILED",
    FETCH_REQUEST: "USERS/FETCH_REQUEST",

    GET_ROLES_FAILED: "ROLES_USERS/GET_FAILED",
    GET_ROLES_REQUEST: "ROLES_USERS/GET_REQUEST",
    GET_ROLES_SUCCESS: "ROLES_USERS/GET_SUCCESS",
    
    UPDATE_FAILED: "USERS/UPDATE_FAILED",
    UPDATE_REQUEST: "USERS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "USERS/UPDATE_SUCCESS",

    CREATE_REQUEST: "USERS/CREATE_REQUEST",
    CREATE_SUCCESS: "USERS/CREATE_SUCCESS",
    CREATE_FAILED: "USERS/CREATE_FAILED",

    EMAIL_REQUEST: "USERS/EMAIL_REQUEST",
    EMAIL_SUCCESS: "USERS/EMAIL_SUCCESS",
    EMAIL_FAILED: "USERS/EMAIL_FAILED",

    DELETE_REQUEST: "USERS/DELETE_REQUEST",
    DELETE_SUCCESS: "USERS/DELETE_SUCCESS",
    DELETE_FAILED: "USERS/DELETE_FAILED",

    GET_FAILED: "USERS/GET_FAILED",
    GET_REQUEST: "USERS/GET_REQUEST",
    GET_SUCCESS: "USERS/GET_SUCCESS",

    TABLE_UPDATE,
};

export default types;
