import appApi from "../App/api";
import {
    webEventsFilters,
    webEventsSettings,
    webEventsTableUpdate,
} from "./actions";
import web from "./api";
import types, {
    FILTERS_SUCCESS,
    SETTINGS_FAILED,
    SETTINGS_SUCCESS,
} from "./types";

import { appTranslate, changeLocale, snackbarShow } from "app/App/actions";
import { request } from "api/apiSaga";
import setLocationHeader from "helpers/setLocationHeader";
import { loadPublicModules } from "moduleSaga";
import queryString from "query-string";
import { call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import {
    SHOW_COOKIE_CONSENT,
    APP_INIT,
    APP_LOADED,
    APP_TRANSLATE_REQUEST,
    APP_TRANSLATE_SUCCESS,
    LOCALE_REQUEST,
    HIDE_COOKIE_CONSENT,
} from "types";
import { addPrefixFilters } from "helpers/urlParams";
import { isEmpty } from "lodash";
import trans from "helpers/trans";
import setCustomHeader from "helpers/setCustomHeader";

interface ITranslate {
    response: {
        data: {
            data: any;
        };
    };
}
interface IFilters {
    response: {
        data: any;
    };
}
interface IRaceEffect {
    failed?: any;
    success?: any;
}
function* initApp() {
    const uuid = window.location.pathname.match(
        /\/tickets\/([A-Za-z0-9-]+)/
    )?.[1];
    const searchParams = window.location.search;
    let paramsObject: any = queryString.parse(searchParams, {
        arrayFormat: "bracket",
        parseNumbers: true,
    });

    const { search, ...restParams } = paramsObject;
    const prefixedFilters = addPrefixFilters(restParams, true);
    const consented = localStorage.getItem("consent");

    if (uuid) {
        if (!consented) {
            yield put(appTranslate({ params: { _location_uid: uuid } }));
            yield take(APP_TRANSLATE_SUCCESS);
            yield put({
                type: SHOW_COOKIE_CONSENT,
                showCookie: true,
            });
            yield take(HIDE_COOKIE_CONSENT);
        }
        const showCookie: boolean = yield select(
            (state: any) => state.app.showCookie
        );

        if (!showCookie) {
            const consentedType = localStorage.getItem("consent");
            if (consentedType) {
                setCustomHeader("Cookie-Level", consentedType, true);
            }

            yield call(loadPublicModules);
            yield put(appTranslate({ params: { location_uid: uuid } }));
            const translate: ITranslate = yield take(APP_TRANSLATE_SUCCESS);
            if (translate) {
                yield put(webEventsSettings(uuid));
                const raceResult: IRaceEffect = yield race({
                    failed: take(SETTINGS_FAILED),
                    success: take(SETTINGS_SUCCESS),
                });

                if (raceResult.success) {
                    const locationId =
                        raceResult.success.response.data.data.location_id;
                    setLocationHeader(locationId, true);
                    yield put(webEventsFilters());
                    const filtersData: IFilters = yield take(FILTERS_SUCCESS);
                    const existingfilters = filtersData.response.data?.map(
                        (item: any) => item.id
                    ) || [];
                    if (filtersData.response.data) {
                       
                        const filteredPrefixedFilters = Object.keys(
                            prefixedFilters
                        ).reduce((acc: any, key: string) => {
                            if (existingfilters.includes(key)) {
                                acc[key] = prefixedFilters[key];
                            }
                            return acc;
                        }, {});

                        if (!isEmpty(filteredPrefixedFilters)) {
                            yield put(
                                webEventsTableUpdate(
                                    "filter",
                                    filteredPrefixedFilters
                                )
                            );
                        }
                    }

                    if (search) {
                        yield put(webEventsTableUpdate("search", search));
                    }
                    yield put({
                        type: APP_LOADED,
                        app: "WebEvent",
                        uuid,
                        filtersList: existingfilters
                    });
                } else {
                    yield put({
                        type: APP_LOADED,
                        app: "WebEvent",
                        uuid: null,
                        status: raceResult.failed.response.data.message,
                    });
                }
            }
        }
    }
}
function* fetch(action: AnyAction) {
    yield call(request, web.fetch, action);
}

function* fetchPayments(action: AnyAction) {
    yield call(request, web.fetchPayments, action);
}

function* get(action: AnyAction) {
    yield call(request, web.get, action);
}

function* settings(action: AnyAction) {
    yield call(request, web.settings, action);
}

function* filters(action: AnyAction) {
    yield call(request, web.filters, action);
}

function* translate(action: AnyAction): any {
    const response = yield call(request, appApi.translate, action);
    if (response.status === 200) {
        (window as any).translate = response.data.data;
        document.documentElement.lang = response.data.locale;
    }
}

function* locale(action: AnyAction): any {
    yield call(translate, changeLocale(action.axiosConfig.config));
    yield put(webEventsFilters());
}

function* store(action: AnyAction): any {
    const response = yield call(request, web.store, action, "post");

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("event.bookings.saga.content.store"),
            })
        );
    }
}

function* getBooking(action: AnyAction) {
    yield call(request, web.getBooking, action);
}

function* checkout(action: AnyAction): any {
    const response = yield call(request, web.checkout, action, "put");

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("event.webEvent.saga.content.checkout"),
            })
        );
    }
}

export const webEventSagas = [
    takeLatest(APP_INIT, initApp),
    takeLatest(types.FETCH_REQUEST, fetch),
    takeLatest(types.FETCH_PAYMENTS_REQUEST, fetchPayments),
    takeLatest(types.GET_REQUEST, get),
    takeLatest(LOCALE_REQUEST, locale),
    takeLatest(types.STORE_REQUEST, store),
    takeLatest(types.GET_BOOKING_REQUEST, getBooking),
    takeLatest(types.SETTINGS_REQUEST, settings),
    takeLatest(types.FILTERS_REQUEST, filters),
    takeLatest(APP_TRANSLATE_REQUEST, translate),
    takeLatest(types.CHECKOUT_REQUEST, checkout),
];
