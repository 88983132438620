import { APP_INIT } from "./types";

import { all, put } from "redux-saga/effects";

import { appSagas } from "app/App/saga";
import { meSagas } from "app/Me/saga";
import { webEventSagas } from "app/WebEvent/saga";

export default function* rootSaga() {
    const uuid = window.location.pathname.match(/\/tickets\/([A-Za-z0-9-]+)/)?.[1];
    if (uuid) {
        yield all(webEventSagas);
    } else {
        yield all([...appSagas, ...meSagas]);
    }

    yield put({ type: APP_INIT });
}
