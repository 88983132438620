import Link from "@mui/material/Link";
import { LinkProps } from "@mui/material";

interface IGPLink extends LinkProps {
    component?: any;
    to?: string;
}

function GPLink({ children, underline = "none", ...restProps }: IGPLink) {
    return (
        <Link underline={underline} {...restProps}>
            {children}
        </Link>
    );
}

export default GPLink;
